import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import { Footer } from 'antd/lib/layout/layout';
import logoPos from '../resources/images/logo-gray.svg';
import logoAlgorim from '../resources/images/logo-algorim.svg'

export default class Login extends React.Component {

    render() {

        return (
            <div className="page-content">
                <div className="login-content">
                    <div className="login-container">
                        <div className="login-image"></div>
                        <img className="login-pos-logo" src={logoPos} alt="" />

                        <Form className="form-login-container" name="login" wrapperCol={{ span: 24 }} initialValues={{ remember: true }} autoComplete="on">
                            <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                                <div>
                                    <label className="gray-small-label">Username</label>
                                    <Input />
                                </div>
                            </Form.Item>

                            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                                <div>
                                    <label className="gray-small-label">Password</label>
                                    <Input.Password />
                                </div>
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <div className="flex-center">
                                    <Link to="/" className="a-no-style">
                                        <Button className="custom-button primary-button" type="primary" htmlType="submit">
                                            Login
                                        </Button>
                                    </Link>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
                <Footer className="footer login-footer">
                    <label className="footer-copyright-text">Copyright All Rights Reserved © 2022 POS Media Czech Republic, s.r.o. | dev by <img className="alg-logo" src={logoAlgorim} alt="" /> Algorim </label>
                </Footer>
            </div>
        );
    }
}