import { Footer } from 'antd/lib/layout/layout';
import React from 'react';
import logo from '../resources/images/logo-algorim.svg'

export class FooterComponent extends React.Component<{ importantText?: string, footerText?: string }> {

    render() {
        const { importantText, footerText } = this.props;

        return <>
            <Footer className="footer">
                <span>
                    {importantText && <label className="footer-important-text">{importantText}</label>}
                    <label>{footerText}</label>
                </span>
                <label className="footer-copyright-text">Copyright All Rights Reserved © 2022 POS Media Czech Republic, s.r.o. | dev by <img className="alg-logo" src={logo} alt="" /> Algorim </label>
            </Footer>
        </>
    };
}