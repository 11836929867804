import { Badge, Button } from 'antd';
import React from 'react';
import HeaderMenu from '../components/header-component';
import { homeBreadCrumb } from '../data/breadcrumb-data';
import { Link } from "react-router-dom";
import { BarChartOutlined, UserOutlined } from '@ant-design/icons';
import { FooterComponent } from '../components/footer-component';

export default class Home extends React.Component {

    render() {

        return (
            <div className="page-content">
                <HeaderMenu title="Home" breadCrumbData={homeBreadCrumb} />

                <div className="home-page-container">
                    <div className="home-page-item-container">
                        <div className="card home-item">
                            <label className="data-label home-item-title"><Badge className="home-item-badge" count={999} overflowCount={999}><BarChartOutlined /></Badge>Campaigns</label>
                            <p className="home-item-description">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Et harum quidem rerum facilis est et expedita distinctio.
                                Nulla accumsan, elit sit amet varius semper, nulla mauris mollis
                            </p>
                            <div className="home-item-footer">
                                <Link to="campaigns" className="a-no-style">
                                    <Button className="custom-button primary-button" type="primary">
                                        Open
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
                <FooterComponent />
            </div>
        );
    }
}