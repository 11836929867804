import { Button, DatePicker, Divider, Drawer, Input, Modal, Progress, Select, Space, Table, Tag, Typography } from 'antd';
import { Key, useEffect, useState } from 'react';
import { FooterComponent } from '../components/footer-component';
import HeaderMenu from '../components/header-component';
import { campaignsBreadCrumb } from '../data/breadcrumb-data';
import filterIcon from '../resources/images/filtr_icon_white.svg';
import closeIcon from '../resources/images/close_icon_white.svg';
import filterIconGray from '../resources/images/filter_icon_gray.svg';
import closeIconGray from '../resources/images/close_icon_gray.svg';
import { CopyOutlined, ExclamationCircleOutlined, PlusOutlined, EditOutlined, LockOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactSplit, { SplitDirection } from '@devbookhq/splitter';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { calcPercentageDays, getOfferExpiration } from '../tools/progressbar-duration-helper';

const { Option } = Select;

const campaigns = [
    {
        id: 1,
        pno: 'P2201001',
        name: "AC Marca - Sanytol fitness",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
        status: "Running",
        campaignFrom: "AC MARCA Czech Republic s.r.o.",
        invoiceTo: "AC MARCA Czech Republic s.r.o.",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        billed: 1,
        invoice: "INV",
        company: "EMP CZ",
        quotations: 25,
        qtsApproved: true,
        incomplete: false,
    },
    {
        id: 2,
        pno: '',
        name: "Albert opening Moskevská",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        campaignFrom: "Albert Česká republika, s.r.o.",
        invoiceTo: "Albert Česká republika, s.r.o.",
        ams: "Lucie Straková",
        amsColor: "#b4bd65",
        total: "50 000",
        currency: "CZK",
        billed: 1,
        invoice: "NOINV",
        company: "ACT CZ",
        quotations: 3,
        qtsApproved: false,
        incomplete: false,
    },
    {
        id: 3,
        pno: '',
        name: "HostessTest",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
        status: "Created",
        campaignFrom: '"AGRO- LA", spol.s r.o."',
        invoiceTo: '"AGRO- LA", spol. s r.o.',
        ams: "David Voráček",
        amsColor: "#bd8365",
        total: "50 000",
        currency: "CZK",
        billed: 1,
        invoice: "NOINV",
        company: "ACT CZ",
        quotations: 314,
        qtsApproved: false,
        incomplete: false,
    },
    {
        id: 4,
        pno: '',
        name: "JTI regionální hostesky 012022",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
        status: "Created",
        campaignFrom: "JT International spol. s r.o.",
        invoiceTo: "JT International spol. s r.o.",
        ams: "Lukáš Novotný",
        amsColor: "#649cbd",
        total: "50 000",
        currency: "CZK",
        billed: 1,
        invoice: "NOINV",
        company: "EMP CZ",
        quotations: 47,
        qtsApproved: false,
        incomplete: true,
    },
    {
        id: 5,
        pno: '',
        name: "Vánoce Coca-Cola s.r.o.",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
        status: "Created",
        campaignFrom: "Coca-Cola s.r.o.",
        invoiceTo: "Coca-Cola s r.o.",
        ams: "Lukáš Novotný",
        amsColor: "#649cbd",
        total: "",
        currency: "",
        billed: 0,
        invoice: "NOINV",
        company: "EMP CZ",
        quotations: 47,
        qtsApproved: false,
        incomplete: true,
    },
    {
        id: 6,
        pno: 'P2201001',
        name: "AC Marca - Sanytol fitness",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
        status: "Running",
        campaignFrom: "AC MARCA Czech Republic s.r.o.",
        invoiceTo: "AC MARCA Czech Republic s.r.o.",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        billed: 1,
        invoice: "PINV",
        company: "EMP CZ",
        quotations: 25,
        qtsApproved: true,
        incomplete: false,
    },
]

const quotations = [
    {
        id: 1,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 4, 10)),
        status: "Ordered",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 21,
        draft: 10,
        ordered: 60,
    },
    {
        id: 2,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Reserved",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 3,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Reserved",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 4,
        campaignId: 2,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 5,
        campaignId: 3,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Reserved",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 6,
        campaignId: 3,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Reserved",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 7,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 5, 10)),
        status: "Reserved",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 8,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 4, 10)),
        status: "Reserved",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 9,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 10,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 11,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 4, 1)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 12,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 13,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 14,
        campaignId: 6,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 4, 1)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Ordered",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 15,
        campaignId: 6,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Cancelled",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 16,
        campaignId: 6,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Cancelled",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 17,
        campaignId: 6,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Cancelled",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
]

export function QuotationTriStatus({ item, hasOrderedStatus }: any) {
    if (!item) {
        return <></>;
    }

    const carriers = item.reserved + item.draft + item.ordered;
    const width = [] as string[];

    width.push(((item.draft / carriers) * 100) + "%");
    width.push(((item.reserved / carriers) * 100) + "%");
    width.push(((item.ordered / carriers) * 100) + "%");

    return (
        <>
            {hasOrderedStatus && item.status.toLowerCase() != "ordered"
                ?
                <div className="quotation-tri-status-container">
                    <div className="quotation-tri-status-item disabled" style={{ width: '100%' }}></div>
                </div>
                :
                <div className="quotation-tri-status-container">
                    {item.draft != 0 && <div className="quotation-tri-status-item draft" style={{ width: width[0] }}></div>}
                    {item.reserved != 0 && <div className="quotation-tri-status-item reserved" style={{ width: width[1] }}></div>}
                    {item.ordered != 0 && <div className="quotation-tri-status-item ordered" style={{ width: width[2] }}></div>}
                </div>
            }
        </>
    );
}

export function Campaigns() {
    const [tableOptions, setTableOptions] = useState({ pageSize: 50, showSizeChanger: false, total: 1, current: 1, loading: false });
    const [newCampaignModal, setNewCampaignModal] = useState({ visibility: false, isLoading: false });
    const [newQuotationModal, setNewQuotationModal] = useState(false);
    const [newClientModal, setNewClientModal] = useState({ visibility: false, isLoading: false });
    const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);
    const [campaignFilterDrawerVisible, setCampaignFilterDrawerVisible] = useState(false);
    const [quotationFilterDrawerVisible, setQuotationFilterDrawerVisible] = useState(false);
    const [hasOrderedStatus, setHasOrderedStatus] = useState(false);
    const [campaignSplitterHeight, setCampaignSplitterHeight] = useState(64);
    const [quotationSplitterHeight, setQuotationSplitterHeight] = useState(36);

    // events
    const handleShowCampaignFilterDrawer = (campaignFilterDrawerVisible: boolean) => {
        setCampaignFilterDrawerVisible(campaignFilterDrawerVisible);
    };

    const handleShowQuotationFilterDrawer = (quotationFilterDrawerVisible: boolean) => {
        setQuotationFilterDrawerVisible(quotationFilterDrawerVisible);
    };

    const handleChangePaginationSize = (pageSize: number) => {
        setTableOptions({ ...tableOptions, pageSize: pageSize });
    };

    const onSelectRadioChange = (selectedKey: Key[]) => {
        setSelectedRowKeys(selectedKey);
    };

    const onSelectRowChange = (index?: number) => {
        const keys = [] as Key[];
        if (index != null) {
            const key = index + 1;
            keys.push(key.toString())
            setSelectedRowKeys(keys);
        }
    };

    useEffect(() => {
        const quotationList = quotations.filter(x => x.campaignId == selectedRowKeys[0]).filter(y => y.status.toLowerCase() == "ordered");
        if (quotationList.length > 0) {
            setHasOrderedStatus(true);
        } else {
            setHasOrderedStatus(false);
        }
    }, [selectedRowKeys]);

    const handleSplitterResize = (gutterIdx: number, allSizes: number[]) => {
        //215 is height of "logo-header" (155px) + "footer" (40px) of the page plus some additional pixels
        const pageContentHeight = window.innerHeight - 215;
        let newHeight = 0;
        const el = document.getElementsByClassName('quotation-container') as HTMLCollectionOf<HTMLElement>;

        newHeight = (el[0].scrollHeight / pageContentHeight) * 100;

        setQuotationSplitterHeight(newHeight);
        setCampaignSplitterHeight(100 - newHeight);
    }

    const handleShowNewCampaignModal = (item: any) => {
        setNewCampaignModal({ ...newCampaignModal, visibility: true });
    }

    const handleCloseNewCampaignModal = () => {
        setNewCampaignModal({ ...newCampaignModal, visibility: false });
    }

    const handleShowNewQuotationModal = (visible: boolean) => {
        setNewQuotationModal(visible);
    }

    const handleShowNewClientModal = () => {
        setNewClientModal({ ...newClientModal, visibility: true });
    }

    const handleCloseNewClientModal = () => {
        setNewClientModal({ ...newClientModal, visibility: false });
    }

    const getOrderedQuotation = (item: any) => {
        const quotation = quotations.filter(x => x.campaignId == item.id)?.filter(y => y.status.toLowerCase() == "ordered");

        if (quotation.length > 0) {
            return quotation[0];
        }
    }

    return (
        <div className="page-content">
            <HeaderMenu title="Campaigns" breadCrumbData={campaignsBreadCrumb} />

            <div className="filter-buttons">
                {!campaignFilterDrawerVisible &&
                    <img className="filter-icon" src={filterIcon} alt="" onClick={() => handleShowCampaignFilterDrawer(true)} />}
                {campaignFilterDrawerVisible &&
                    <img className="filter-icon" src={closeIcon} alt="" onClick={() => handleShowCampaignFilterDrawer(false)} />}
            </div>

            <div className="page-body">
                <ReactSplit classes={["splitter campaigns-container", "quotation-container"]} direction={SplitDirection.Vertical} gutterClassName="drag-bar"
                    minHeights={[200, 160]} initialSizes={[campaignSplitterHeight, quotationSplitterHeight]} onResizeFinished={handleSplitterResize} >
                    <div className="splitter campaign-body">
                        <Table className="campaigns-scrollable-table table-nowrap custom-pagination" dataSource={campaigns} rowKey={campaign => `${campaign.id}`} scroll={{ x: true, y: '100%' }}
                            pagination={tableOptions} loading={tableOptions.loading} rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onSelectRadioChange, type: 'radio' }}
                            onRow={(record, index) => { return { onClick: event => onSelectRowChange(index) } }}
                            rowClassName={`campaign-row row-selection-cursor`}
                            summary={() => (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className="pagination-row">
                                        <Table.Summary.Cell className="summary-cell" index={0} colSpan={13}>
                                            <div className="campaign-legend-container">
                                                <Button className="custom-button" type="primary" onClick={handleShowNewCampaignModal}>New campaign</Button>
                                            </div>
                                            <Select className="pagination-changer" value={tableOptions.pageSize} onChange={e => handleChangePaginationSize(e)} >
                                                <Option value={20}>20</Option>
                                                <Option value={50}>50</Option>
                                                <Option value={100}>100</Option>
                                            </Select>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                            columns={[
                                {
                                    key: 'pno',
                                    title: 'P. No.',
                                    fixed: 'left',
                                    width: 100,
                                    render: (_, record) => <label className="pno-cell">{record.pno}</label>,
                                },
                                {
                                    key: 'name',
                                    title: () =>
                                        <div className="name-error">
                                            <ExclamationCircleOutlined className="error-cell" />
                                            <label>Name</label>
                                        </div>,
                                    render: (_, record) =>
                                        <div className="name-error">
                                            {record.incomplete ? <ExclamationCircleOutlined className="error-cell red" /> : <ExclamationCircleOutlined className="error-cell hidden" />}
                                            <label>{record.name}</label>
                                        </div>,
                                },
                                {
                                    key: 'interval',
                                    title: 'Interval',
                                    render: (_, record) => <>{record.dateFrom.format("DD.MM.YYYY")} <Progress className={`timeline-progress`} data-progress={record.status.toLowerCase()} percent={calcPercentageDays(record.dateFrom, record.dateTo)} showInfo={false} /> {record.dateTo.format("DD.MM.YYYY")}</>,
                                },
                                {
                                    key: 'status',
                                    title: 'Status',
                                    render: (_, record) =>
                                        <div className="campaign-status-container">
                                            <label className={`campaign-status ${record.status.toLowerCase()}`} >{record.status}</label>
                                        </div>,
                                },
                                {
                                    key: 'campaignFrom',
                                    title: 'Campaign From',
                                    render: (_, record) => <>{record.campaignFrom}</>,
                                },
                                {
                                    key: 'invoiceTo',
                                    title: 'Invoice To',
                                    render: (_, record) => <>{record.invoiceTo}</>,
                                },
                                {
                                    key: 'ams',
                                    title: 'AMS',
                                    render: (_, record) => <><label className="campaign-table-ams" style={{ background: record.amsColor }}>{record.ams.match(/[A-Z]/g)?.join('')}</label></>,
                                },
                                {
                                    key: 'total',
                                    title: 'Total',
                                    align: 'right',
                                    render: (_, record) =>
                                        <div className="amount-cell-container">
                                            <label className="amount-cell-text">{record.total}</label>
                                            <label className="amount-cell-currency">{record.currency}</label>
                                        </div>,
                                },
                                {
                                    key: 'invoice',
                                    title: 'Invoice',
                                    render: (_, record) =>
                                        <div className="campaign-invoice-container">
                                            <label className={`campaign-invoice ${record.invoice.toLowerCase().replace(/\s/g, "")}`} >{record.invoice}</label>
                                        </div>,
                                },
                                {
                                    key: 'company',
                                    title: 'Company',
                                    width: 80,
                                    render: (_, record) =>
                                        <div className="campaign-company-container">
                                            <label className={`campaign-company ${record.company.toLowerCase().replace(/\s/g, "")}`} >{record.company}</label>
                                        </div>,
                                },
                                {
                                    key: 'quotations',
                                    title: 'Quotations',
                                    render: (_, record) => <>
                                        <QuotationTriStatus item={getOrderedQuotation(record)} hasOrderedStatus={hasOrderedStatus} />
                                    </>,
                                },
                                {
                                    key: 'actions',
                                    title: 'Actions',
                                    align: 'right',
                                    render: (_, record) => <div className="buttons-container table-buttons-right-align">
                                        <Link to="/quotation-detail" className="a-no-style">
                                            <Button className="custom-button primary-button" type="primary" disabled={!record.qtsApproved}>Carrier</Button>
                                        </Link>
                                        <EditOutlined className="icon" onClick={() => handleShowNewCampaignModal(record)} />
                                    </div>,
                                },
                            ]}
                        />

                        <Drawer title="Campaign filter" placement="right" width={360} onClose={() => handleShowCampaignFilterDrawer(false)} visible={campaignFilterDrawerVisible}>
                            <div className="filter-container">
                                <div className="filter-item">
                                    <label className="gray-small-label">P. No.</label>
                                    <Input />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Name</label>
                                    <Input />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Date from</label>
                                    <DatePicker />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Date to</label>
                                    <DatePicker />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Status</label>
                                    <Select allowClear showSearch
                                        filterOption={(input, option) =>
                                            option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                        }>
                                        <Option value="1">Running</Option>
                                    </Select>
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Invoice to</label>
                                    <Input />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Total</label>
                                    <Input />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Company</label>
                                    <Input />
                                </div>

                                <div className="drawer-button-container">
                                    <Button className="custom-button primary-button" type="primary" onClick={() => handleShowCampaignFilterDrawer(false)}>Search</Button>
                                    <Button className="custom-button default-button" type="default" onClick={() => handleShowCampaignFilterDrawer(false)}>Reset</Button>
                                    <Button className="custom-button default-button" type="default" onClick={() => handleShowCampaignFilterDrawer(false)}>Cancel</Button>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                    <div className="splitter quotation-body">
                        <div className="quotation-title-container">
                            <label className="splitter-title">Quotations</label>
                            <div className="quotation-buttons-container">
                                {!quotationFilterDrawerVisible &&
                                    <img className="quotation-filter-icon" src={filterIconGray} alt="" onClick={() => handleShowQuotationFilterDrawer(!quotationFilterDrawerVisible)} />}
                                {quotationFilterDrawerVisible &&
                                    <img className="quotation-filter-icon" src={closeIconGray} alt="" onClick={() => handleShowQuotationFilterDrawer(!quotationFilterDrawerVisible)} />}
                            </div>
                        </div>

                        <Table className="campaigns-scrollable-table table-nowrap custom-pagination" dataSource={quotations.filter(x => x.campaignId == selectedRowKeys[0])}
                            rowKey={quotation => `${quotation.id}`} scroll={{ x: true, y: '100%' }} pagination={tableOptions} loading={tableOptions.loading}
                            rowClassName={(x) => `campaign-row ${x.status.toLowerCase().replace(/\s/g, "") === 'ordered' && 'quotation-accepted-row'}`}
                            summary={() => (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className="pagination-row">
                                        <Table.Summary.Cell className="summary-cell" index={0} colSpan={12}>
                                            <div className="campaign-legend-container">
                                                <Button className="custom-button" type="primary" onClick={() => handleShowNewQuotationModal(true)}>New Quotation</Button>
                                                <div className="campaign-legend-icons">
                                                    <div className="quotation-tri-status-legend-container">
                                                        <div className="quotation-tri-status-item-legend draft"></div>
                                                        <label className="campaign-legend-label">Draft</label>
                                                    </div>
                                                    <div className="quotation-tri-status-legend-container">
                                                        <div className="quotation-tri-status-item-legend reserved"></div>
                                                        <label className="campaign-legend-label">Reserved</label>
                                                    </div>
                                                    <div className="quotation-tri-status-legend-container">
                                                        <div className="quotation-tri-status-item-legend ordered"></div>
                                                        <label className="campaign-legend-label">Ordered</label>
                                                    </div>
                                                    <div className="quotation-tri-status-legend-container">
                                                        <div className="quotation-tri-status-item-legend disabled"></div>
                                                        <label className="campaign-legend-label">Disabled</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <Select className="pagination-changer" value={tableOptions.pageSize} onChange={e => handleChangePaginationSize(e)} >
                                                <Option value={20}>20</Option>
                                                <Option value={50}>50</Option>
                                                <Option value={100}>100</Option>
                                            </Select>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                            columns={[
                                {
                                    key: 'id',
                                    title: 'ID',
                                    fixed: 'left',
                                    width: 100,
                                    render: (_, record) => <label className="pno-cell">{record.id}</label>,
                                },
                                {
                                    key: 'offerName',
                                    title: () =>
                                        <div className="name-error">
                                            <ExclamationCircleOutlined className="error-cell" />
                                            <label>Offer name</label>
                                        </div>,
                                    render: (_, record) =>
                                        <div className="name-error">
                                            {getOfferExpiration(record.offerValidityFrom, record.offerValidityTo) == "expired" ? <ExclamationCircleOutlined className="error-cell red" /> : <ExclamationCircleOutlined className="error-cell hidden" />}
                                            <label>{record.offerName}</label>
                                        </div>,
                                },
                                {
                                    key: 'carrierStatus',
                                    title: 'Carrier Status',
                                    render: (_, record) => <QuotationTriStatus item={record} hasOrderedStatus={hasOrderedStatus} />,
                                },
                                {
                                    key: 'offerValidity',
                                    title: 'Offer Validity',
                                    width: 200,
                                    render: (_, record) =>
                                        <span>
                                            {record.offerValidityFrom.format("DD.MM.YYYY")}
                                            {hasOrderedStatus &&
                                                <Progress className={`timeline-progress`} data-progress={getOfferExpiration(record.offerValidityFrom, record.offerValidityTo, record.status.toLowerCase() == "ordered" ? "ordered" : "disabled")}
                                                    percent={100} showInfo={false} />}

                                            {!hasOrderedStatus &&
                                                <Progress className={`timeline-progress`} data-progress={getOfferExpiration(record.offerValidityFrom, record.offerValidityTo)}
                                                    percent={calcPercentageDays(record.offerValidityFrom, record.offerValidityTo)} showInfo={false} />}

                                            {record.offerValidityTo.format("DD.MM.YYYY")}
                                        </span>,
                                },
                                {
                                    key: 'approved',
                                    title: '',
                                    align: 'center',
                                    render: (_, record) => <>
                                        {hasOrderedStatus && record.status.toLowerCase() == "ordered" &&
                                            <Tag className="subcarrier-tag" color="green">Approved date: <b>03.04.2022</b></Tag>
                                        }
                                    </>,
                                },
                                {
                                    key: 'carriers',
                                    title: 'Carriers',
                                    align: 'right',
                                    render: (_, record) => <>{record.reserved + record.draft + record.ordered}</>,
                                },
                                {
                                    key: 'reserved',
                                    title: 'Reserved',
                                    align: 'right',
                                    render: (_, record) => <>{record.reserved}</>,
                                },
                                {
                                    key: 'total',
                                    title: 'Total',
                                    align: 'right',
                                    render: (_, record) =>
                                        <div className="amount-cell-container">
                                            <label className="amount-cell-text">{record.total}</label>
                                            <label className="amount-cell-currency">{record.currency}</label>
                                        </div>,
                                },
                                {
                                    key: 'description',
                                    title: 'Description',
                                    align: 'right',
                                    render: (_, record) => <>{record.description}</>,
                                },
                                {
                                    key: 'actions',
                                    title: 'Actions',
                                    width: 80,
                                    fixed: 'right',
                                    align: 'right',
                                    render: (_, record) =>
                                        <div className="buttons-container table-buttons-right-align">
                                            <Link to="/quotation-detail" className="a-no-style">
                                                <Button className="custom-button primary-button" type={`${hasOrderedStatus && record.status.toLowerCase() != "ordered" ? 'default' : 'primary'}`}>Detail</Button>
                                            </Link>
                                            {hasOrderedStatus ? <LockOutlined className="common-icon" /> : <CopyOutlined className="icon" />}
                                            <DeleteOutlined className="icon red-hover" />
                                        </div>,
                                },
                            ]}
                        />

                        <Drawer title="Quotation filter" placement="right" width={360} onClose={() => handleShowQuotationFilterDrawer(false)} visible={quotationFilterDrawerVisible}>
                            <div className="filter-container">

                                <div className="filter-item">
                                    <label className="gray-small-label">Name</label>
                                    <Input />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Client</label>
                                    <Input />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Client contact</label>
                                    <Input />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Status</label>
                                    <Select allowClear showSearch
                                        filterOption={(input, option) =>
                                            option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                        }>
                                        <Option value="1">Ordered</Option>
                                        <Option value="2">Offer</Option>
                                        <Option value="3">Reserved</Option>
                                    </Select>
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Date from</label>
                                    <DatePicker />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Date to</label>
                                    <DatePicker />
                                </div>

                                <div className="filter-item">
                                    <label className="gray-small-label">Total</label>
                                    <Input />
                                </div>

                                <div className="drawer-button-container">
                                    <Button className="custom-button primary-button" type="primary" onClick={() => handleShowQuotationFilterDrawer(false)}>Search</Button>
                                    <Button className="custom-button default-button" type="default" onClick={() => handleShowQuotationFilterDrawer(false)}>Reset</Button>
                                    <Button className="custom-button default-button" type="default" onClick={() => handleShowQuotationFilterDrawer(false)}>Cancel</Button>
                                </div>
                            </div>
                        </Drawer>
                    </div>

                </ReactSplit>
            </div>

            <Modal title="New campaign"
                visible={newCampaignModal.visibility}
                onOk={() => handleCloseNewCampaignModal()}
                okButtonProps={{ loading: newCampaignModal.isLoading }}
                onCancel={() => handleCloseNewCampaignModal()}
                cancelButtonProps={{ loading: newCampaignModal.isLoading }}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Campaign name</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Company</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <div className="card-item">
                            <label className="gray-small-label">Campaign from</label>
                            <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                                filterOption={(input, option) =>
                                    option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }
                            >
                                <Option value="1">Kontakt 1</Option>
                                <Option value="2">Kontakt 2</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Campaign from contact</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={() => handleShowNewClientModal()} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined /> Create new contact
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="1">Kontakt 1</Option>
                            <Option value="2">Kontakt 2</Option>
                        </Select>
                    </div>

                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">AMS</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                        >
                            <Option value="1">Osoba 1</Option>
                            <Option value="2">Osoba 2</Option>
                        </Select>
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Date from</label>
                        <DatePicker />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Date to</label>
                        <DatePicker />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Invoice to</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={() => handleShowNewClientModal()} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined /> Create new contact
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="1">Kontakt 1</Option>
                            <Option value="2">Kontakt 2</Option>
                        </Select>
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Currency</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                        >
                            <Option value="1">Měna 1</Option>
                            <Option value="2">Měna 2</Option>
                        </Select>
                    </div>
                </div>

            </Modal>

            <Modal title="New quotation"
                visible={newQuotationModal}
                onOk={() => handleShowNewQuotationModal(false)}
                onCancel={() => handleShowNewQuotationModal(false)}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Offer name</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Date from</label>
                        <DatePicker />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Date to</label>
                        <DatePicker />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Description</label>
                        <Input />
                    </div>
                </div>
            </Modal>

            <Modal title="New client"
                wrapClassName="new-client-modal"
                visible={newClientModal.visibility}
                onOk={() => handleCloseNewClientModal()}
                okButtonProps={{ loading: newClientModal.isLoading }}
                onCancel={() => handleCloseNewClientModal()}
                cancelButtonProps={{ loading: newClientModal.isLoading }}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Name</label>
                        <Input />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Phone</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">E-mail</label>
                        <Input />
                    </div>
                </div>

            </Modal>
            <FooterComponent />
        </div>
    );

}