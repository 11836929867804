export class BreadCrumbMock {
    href = '';
    text?= '';
}

export const loginBreadCrumb: BreadCrumbMock[] = [
    {
        href: '',
        text: 'login'
    },
];

export const errorBreadCrumb: BreadCrumbMock[] = [
    {
        href: '',
        text: 'home'
    },
    {
        href: '',
        text: '404'
    },
];

export const homeBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: ''
    },
    {
        href: '/',
        text: 'home'
    },
];

export const financeBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: ''
    },
    {
        href: '/finance',
        text: 'finance'
    },
];

export const campaignsBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: ''
    },
    {
        href: '/campaigns',
        text: 'campaigns'
    },
];

export const reservationListBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: ''
    },
    {
        href: '/reservation-list',
        text: 'reservations'
    },
];

export const subcarrierListBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: ''
    },
    {
        href: '/subcarrier-list',
        text: 'subcarriers'
    },
];