import moment from "moment";

export const calcPercentageDays = (dateFrom: moment.Moment | null, dateTo: moment.Moment | null) => {
    if (!dateFrom || !dateTo) {
        return;
    }

    const daysFromTo = dateTo.diff(dateFrom, 'days');
    const daysFromNow = moment().diff(dateFrom, 'days');
    const daysPercentage = daysFromNow / daysFromTo * 100;

    return daysPercentage;
}

export const getProgressStatus = (dateFrom: moment.Moment | null, dateTo: moment.Moment | null, cancelled: boolean = false) => {
    if (!dateFrom || !dateTo) {
        return;
    }

    const percentage = calcPercentageDays(dateFrom, dateTo);

    if (percentage == null) {
        return 0;
    }

    if (cancelled) {
        return "cancelled";
    }

    if (percentage <= 0) {
        return "waiting";
    } else if (percentage <= 99) {
        return "started";
    } else if (percentage >= 100) {
        return "finished";
    }
}

export const getOfferExpiration = (dateFrom: moment.Moment | null, dateTo: moment.Moment | null, quotationAccepted: string = '') => {
    if (!dateFrom || !dateTo) {
        return;
    }

    if (quotationAccepted == 'disabled') {
        return "disabled"
    };

    if (quotationAccepted == 'ordered') {
        return "valid"
    };

    const percentage = calcPercentageDays(dateFrom, dateTo);

    if (!percentage) {
        return 0;
    }

    if (percentage < 50) {
        return "valid";
    } else if (percentage < 75) {
        return "warning";
    } else if (percentage < 99) {
        return "expiring";
    } else {
        return "expired";
    }
}