import { Button, Collapse, DatePicker, Divider, Drawer, Dropdown, Input, InputNumber, Menu, Modal, Progress, Radio, Select, Space, Table, Tabs, Tag, Typography } from 'antd';
import { Key, useEffect, useState } from 'react';
import { FooterComponent } from './footer-component';
import HeaderMenu from './header-component';
import { campaignsBreadCrumb } from '../data/breadcrumb-data';
import { EditOutlined, CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, PhoneOutlined, PlusOutlined, StopOutlined, CheckCircleOutlined, CalendarOutlined, DropboxOutlined } from '@ant-design/icons';
import { CarriersModel } from '../data/carriers-model';
import summaryPreview from '../resources/screenshots/summary_preview.png'
import moment from 'moment';
import filterIcon from '../resources/images/filtr_icon_white.svg';
import closeIcon from '../resources/images/close_icon_white.svg';
import { calcPercentageDays, getOfferExpiration, getProgressStatus } from '../tools/progressbar-duration-helper';

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Panel } = Collapse;

const subCarriers = [
    {
        storeId: 19247,
        format: "HM",
        name: "Plzeň",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Reserved",
        quantity: "1",
        type: "SS",
    },
    {
        storeId: 162,
        format: "HM",
        name: "Brno",
        city: "Brno",
        street: "U dálnice 744, 664 42, Brno",
        size: "0K",
        closedFrom: "-",
        closedTo: "15. 05. 2022",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "FG",
    },
    {
        storeId: 195,
        format: "HM",
        name: "Praha - Nové Butovice",
        city: "Praha - Nové Butovice",
        street: "Radlická 117, 153 00, Praha 5",
        size: "0K",
        closedFrom: "-",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "PSS",
    },
    {
        storeId: 829,
        format: "HM",
        name: "Praha - Arkády",
        city: "Praha - Arkády",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "0K",
        closedFrom: "-",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "Typ1",
    },
    {
        storeId: 348,
        format: "HM",
        name: "Vyškov",
        city: "Vyškov",
        street: "U Jandovky 2, 682 01, Vyškov",
        size: "0K",
        closedFrom: "-",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "Typ2",
    },
];

export function PartnerTag({ item }: any) {
    const partner = item.partner?.toLowerCase().replace(/\s/g, "");
    let tag: React.ReactNode;

    if (partner == "albert") {
        tag = <Tag color="green">{item.partner}</Tag>;
    } else if (partner == "globus") {
        tag = <Tag color="orange">{item.partner}</Tag>;
    } else if (partner == "makro") {
        tag = <Tag color="gold">{item.partner}</Tag>;
    } else if (partner == "rossmann") {
        tag = <Tag color="magenta">{item.partner}</Tag>;
    } else if (partner == "tesco") {
        tag = <Tag color="geekblue">{item.partner}</Tag>;
    } else if (partner == "kaufland") {
        tag = <Tag color="red">{item.partner}</Tag>;
    } else if (partner == "penny") {
        tag = <Tag color="volcano">{item.partner}</Tag>;
    } else if (partner == "electroworld") {
        tag = <Tag color="blue">{item.partner}</Tag>;
    } else {
        tag = <Tag color="purple">{item.partner}</Tag>;
    }

    return <span>{tag}</span>;
}

interface TypeColor {
    type: string;
    color: string;
}

enum colors {
    purple = "#531dab",
    geekblue = "#1d39c4",
    blue = "#096dd9",
    cyan = "#08979c",
}

export function CarrierEditModal({visibility, data, onClose}:any) {
    const [carriersDrawerData, setCarriersDrawerData] = useState({ visibility: false, data: new CarriersModel() });
    const [multiEditSubcarrierModal, setMultiEditSubcarrierModal] = useState({ visibility: false, isLoading: false });
    const [subcarrierSelectedRowKeys, setSubcarrierSelectedRowKeys] = useState([] as Key[]);
    const [selectedTabKey, setSelectedTabKey] = useState("1");
    const [subcarriersTypeColor, setSubcarriersTypeColor] = useState([] as TypeColor[]);
    const [subcarrierCollapse, setSubcarrierCollapse] = useState('0');
    const [modalVisibility, setModalVisibility] = useState(false);
    const [tableData, setTableData] = useState(new CarriersModel());

    useEffect(() => {
        setModalVisibility(visibility);
        setTableData(data);
        console.log(tableData);
    });

    const onSubcarrierTableSelectChange = (subcarrierSelectedRowKeys: Key[]) => {
        setSubcarrierSelectedRowKeys(subcarrierSelectedRowKeys);
    };

    const getCarrierTypeColor = (type: string) => {
        const color = subcarriersTypeColor.find(x => x.type == type);
        if (color) {
            return color.color;
        } else {
            return "#aaa";
        }
    }

    const handleCloseSubcarierModal = () => {
        setSubcarrierSelectedRowKeys([]);
        setCarriersDrawerData({ visibility: false, data: new CarriersModel() });
        setModalVisibility(false);
    };

    const handleShowMultiEditSubcarrierModal = (visible: boolean) => {
        setMultiEditSubcarrierModal({ ...multiEditSubcarrierModal, visibility: visible });
    };

    const handleDeleteSubcarrierModal = () => {
        confirm({
            title: "Are you sure delete subcarrier",
            icon: <ExclamationCircleOutlined />,
            content: (<>Text</>),
            okText: "Yes",
            okType: 'danger',
            cancelText: "No",
            centered: true,
            maskClosable: true,
            onOk: async () => {
                console.log("Ok");
            },
        });
    }

    const handleChangeTab = (key: string) => {
        setSelectedTabKey(key);
    }

    const handleSubcarrierCollapse = (key: any) => {
        if(subcarrierCollapse == '0') {
            setSubcarrierCollapse('1');
        } else {
            setSubcarrierCollapse('0');
        }        
    }

    return (
        <Modal className="subcarrier-modal"
        title={tableData.id ? "AC Marca - Sanytol fitness: " + tableData.name : "AC Marca - Sanytol fitness: New Carrier"}
        visible={modalVisibility}
        onOk={() => handleCloseSubcarierModal()}
        onCancel={onClose}
        centered
        width={1600}
        footer={[
            <div className="subcarrier-footer-buttons-container">
                <div>
                    {selectedTabKey == "1" && <Button key="addad" type="primary" disabled={!tableData.id}>Add item</Button>}
                    {subcarrierSelectedRowKeys.length > 0 &&
                        <>
                            <Dropdown.Button className="subcarrier-dropdown-button" placement="topRight" onClick={() => handleShowMultiEditSubcarrierModal(true)}
                                overlay={() =>
                                    <Menu className="task-detail-dropdown-menu-container">
                                        <Menu.Item key="6" onClick={() => handleDeleteSubcarrierModal()}>
                                            <DeleteOutlined className="dropdown-custom-button-icon" />Delete
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                Edit
                            </Dropdown.Button>
                        </>
                    }
                </div>
                <div>
                    <Button key="cancel" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button key="submit" type="primary" onClick={() => handleCloseSubcarierModal()}>
                        Save
                    </Button>
                </div>

            </div>
        ]}
    >
        <div className="carrier-detail-summary-container">
            <div className={`carrier-detail-summary-item ${selectedTabKey == "1" && "active"}`}>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price-title">Price</label>
                    <label className="carrier-detail-summary-item-price-title">Discount</label>
                    <label className="carrier-detail-summary-item-price-title">Sum</label>
                </div>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                    <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                </div>
            </div>

            <div className={`carrier-detail-summary-item ${selectedTabKey == "2" && "active"}`}>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price-title">Price</label>
                    <label className="carrier-detail-summary-item-price-title">Discount</label>
                    <label className="carrier-detail-summary-item-price-title">Sum</label>
                </div>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                    <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                </div>
            </div>

            <div className={`carrier-detail-summary-item ${selectedTabKey == "3" && "active"}`}>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price-title">Price</label>
                    <label className="carrier-detail-summary-item-price-title">Discount</label>
                    <label className="carrier-detail-summary-item-price-title">Sum</label>
                </div>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                    <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                </div>
            </div>

            <div className={`carrier-detail-summary-item ${selectedTabKey == "4" && "active"}`}>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price-title">Price</label>
                    <label className="carrier-detail-summary-item-price-title">Discount</label>
                    <label className="carrier-detail-summary-item-price-title">Sum</label>
                </div>
                <div className="carrier-detail-summary-item-price-container">
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                    <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                    <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                </div>
            </div>

        </div>

        <Tabs className="carrier-detail-tabs" type="card" onChange={(key) => handleChangeTab(key)}>
            <TabPane tab="Summary" key="1">
                <div className="subcarrier-detail-tab-container">

                    <Collapse activeKey={subcarrierCollapse} onChange={(key) => handleSubcarrierCollapse(key)}>
                        <Panel className="subcarrier-collapse-carrier-detail" header="Carriers detail" key="1">
                            <div className="card-container">
                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Partner</label>
                                    <Select allowClear showSearch value={tableData.partner?.toLowerCase()}>
                                        <Option value="albert">Albert</Option>
                                        <Option value="billa">Billa</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Format</label>
                                    <Select allowClear showSearch value={tableData.format?.toLowerCase()} disabled={tableData.partner != ""}>
                                        <Option value="hm">HM</Option>
                                        <Option value="sm">SM</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter"></div>

                                <div className="card-item one-quarter"></div>

                            </div>

                            <div className="card-container">

                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Package</label>
                                    <Select allowClear showSearch value={tableData.preset?.toLowerCase().replace(/\s/g, "")} disabled={tableData.type != ""}>
                                        <Option value="package1">Package 1 (BL)</Option>
                                        <Option value="package2">Package 2</Option>
                                        <Option value="package3">Package 3</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Carrier</label>
                                    <Select allowClear showSearch value={tableData.type?.toLowerCase().replace(/\s/g, "")} disabled={tableData.preset != ""}>
                                        <Option value="ss">Shelf Stopper [SS]</Option>
                                        <Option value="pss">Shelf Stopper LED - Premiový [PSS]</Option>
                                        <Option value="fg">Podlahová grafika [FG]</Option>
                                        <Option value="lama">Lama</Option>
                                        <Option value="energy">Energy</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Variant</label>
                                    <Select allowClear showSearch disabled={tableData.type != "" || tableData.preset != ""}>
                                        <Option value="1">A0 - EM4TD</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter"></div>

                            </div>

                            <div className="card-container">
                                <div className="card-item half">
                                    <label className="gray-small-label">Individual name</label>
                                    <Input />
                                </div>

                                <div className="card-item half">
                                    <label className="gray-small-label">Note</label>
                                    <Input />
                                </div>

                            </div>
                        </Panel>
                    </Collapse>



                    <div className="card-container">
                        <div className="subcarrier-tag-container">
                            <Tag className="subcarrier-tag" color="geekblue">Current pieces: <b>199</b></Tag>
                            <Tag className="subcarrier-tag" color="purple">Current shops: <b>199</b></Tag>
                            <Tag className="subcarrier-tag" color="blue">Quantity ammount: <b>199</b></Tag>
                        </div>
                    </div>

                    <div>
                        <div className="quotation-detail-table-title-container">
                            <label className="quotation-detail-table-title">Locations</label>
                            <div className="subcarrier-table-header">
                                <label className="gray-small-label subcarrier-table-input-button-label">Fix counts:</label>
                                <span className="subcarrier-table-input-button">
                                    <InputNumber size="small" />
                                    <Button className="custom-button" type="primary" size="middle">Apply</Button>
                                </span>

                                <div className="carrier-status-container subcarrier-table-header-dropdown-button">
                                    <label className={`quotation-status draft`} >Draft</label>
                                    <Dropdown.Button className="dropdown-custom-button dropdown-custom-button-carrier-status" data-button={'draft'} placement="topRight"
                                        overlay={() =>
                                            <Menu className="dropdown-menu-container">
                                                <Menu.Item key="1">
                                                    <EditOutlined className="dropdown-custom-button-icon" />Draft
                                                </Menu.Item>
                                                <Menu.Item key="2">
                                                    <CalendarOutlined className="dropdown-custom-button-icon" />Reserved
                                                </Menu.Item>
                                                <Menu.Item key="3">
                                                    <CheckCircleOutlined className="dropdown-custom-button-icon" />Ordered
                                                </Menu.Item>
                                                <Menu.Item key="4">
                                                    <StopOutlined className="dropdown-custom-button-icon" />Cancel
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        Actions
                                    </Dropdown.Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table className="scrollable-table table-nowrap custom-pagination" dataSource={tableData.id ? subCarriers : []} rowKey={subCarriers => `${subCarriers.storeId}`} scroll={{ x: true, y: '100%' }}
                        pagination={false} rowSelection={{ selectedRowKeys: subcarrierSelectedRowKeys, onChange: onSubcarrierTableSelectChange }}
                        columns={[
                            {
                                key: 'format',
                                title: 'Format',
                                render: (_, record) => <>{record.format}</>,
                            },
                            {
                                key: 'type',
                                title: 'Type',
                                render: (_, record) =>
                                    <div className="carrier-type-container">
                                        <label className="carrier-type" style={{ background: getCarrierTypeColor(record.type) }} >{record.type}</label>
                                    </div>,
                            },
                            {
                                key: 'variant',
                                title: 'Variant',
                                render: (_, record) => <>JHXCFDV</>
                            },
                            {
                                key: 'name',
                                title: 'Name',
                                width: 450,
                                render: (_, record) =>
                                    <div className="card-item">
                                        <Select allowClear showSearch optionLabelProp="label">
                                            <Option value="1" label="Plzeň">Plzeň, Písecká 972/1, 326 00, Plzeň</Option>
                                            <Option value="2" label="Brno">Brno, U dálnice 744, 664 42, Brno</Option>
                                            <Option value="3" label="Praha - Nové Butovice">Praha - Nové Butovice, Radlická 117, 153 00, Praha 5</Option>
                                        </Select>
                                    </div>,
                            },
                            {
                                key: 'from',
                                title: 'From',
                                render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                            },
                            {
                                key: 'to',
                                title: 'To',
                                render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                            },
                            {
                                key: 'size',
                                title: 'Size',
                                width: 90,
                                render: (_, record) => <><InputNumber /></>,
                            },
                            {
                                key: 'closedFrom',
                                title: 'Closed From',
                                render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                            },
                            {
                                key: 'closedTo',
                                title: 'Closed To',
                                render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                            },
                            {
                                key: 'usableAmount',
                                title: 'Usable Amount',
                                align: 'right',
                                render: (_, record) => <>unlimited</>,
                            },
                            {
                                key: 'disposableAmount',
                                title: 'Disposable Amount',
                                align: 'right',
                                render: (_, record) => <>unlimited</>,
                            },
                            {
                                key: 'quantity',
                                title: 'Quantity',
                                render: (_, record) => <><InputNumber className="subcarry-quantity-input" size="small" value={record.quantity} /></>,
                            },
                            {
                                key: 'actions',
                                title: 'Actions',
                                align: 'right',
                                fixed: 'right',
                                width: 80,
                                render: (_, record) =>
                                    <div className="buttons-container table-buttons-right-align">
                                        <DeleteOutlined className="icon red-hover" onClick={() => handleDeleteSubcarrierModal()} />
                                    </div>,
                            },
                        ]}
                    />

                </div>
            </TabPane>
            <TabPane tab="Media price" key="2">
                <div className="subcarrier-detail-tab-container">

                    <div className="card-container">
                        <div className="card-item one-quarter">
                            <label className="gray-small-label">Type</label>
                            <Select allowClear showSearch>
                                <Option value="1">FG (floor graphics)</Option>
                                <Option value="2">SS (shelf stopper</Option>
                                <Option value="3">PSS (shelf stopper led)</Option>
                            </Select>
                        </div>

                        <div className="card-item one-quarter">
                            <label className="gray-small-label">Partner</label>
                            <Select allowClear showSearch>
                                <Option value="1">Albert</Option>
                                <Option value="2">Billa</Option>
                            </Select>
                        </div>

                        <div className="card-item one-quarter">
                            <label className="gray-small-label">Format</label>
                            <Select allowClear showSearch>
                                <Option value="1">HM</Option>
                                <Option value="2">HM</Option>
                            </Select>
                        </div>

                        <div className="card-item one-quarter">
                            <label className="gray-small-label">Fix counts</label>
                            <Input.Group className="subcarrier-input-group-container" compact>
                                <InputNumber />
                                <Button type="primary">Change</Button>
                            </Input.Group>
                        </div>
                    </div>
                </div>
            </TabPane>
            <TabPane tab="Production prices" key="3">
                <div className="carrier-detail-tab-container">
                    <div className="input-row-container">

                        <div className="input-multiple-items-container">
                            <div className="input-row-item">
                                <label className="gray-small-label">Media netnet sum</label>
                                <Input addonAfter={"€"} />
                            </div>
                        </div>

                        <div className="input-multiple-items-container">
                            <div className="input-row-item spacing">
                                <label className="gray-small-label">Prod. sales list price</label>
                                <Input addonAfter={"%"} />
                            </div>
                            <div className="input-row-item">
                                <label className="gray-small-label"></label>
                                <Input addonAfter={"€"} />
                            </div>
                        </div>

                    </div>

                    <div className="input-row-container">

                        <div className="input-multiple-items-container">
                            <div className="input-row-item spacing">
                                <label className="gray-small-label">Prod Buffer</label>
                                <Input addonAfter={"%"} />
                            </div>
                            <div className="input-row-item">
                                <label className="gray-small-label"></label>
                                <Input addonAfter={"PCS"} />
                            </div>
                        </div>


                        <div className="input-multiple-items-container">
                            <div className="input-row-item spacing">
                                <label className="gray-small-label">Total nr</label>
                                <Input addonAfter={"%"} />
                            </div>
                            <div className="input-row-item">
                                <label className="gray-small-label"></label>
                                <Input addonAfter={"PCS"} />
                            </div>
                        </div>

                    </div>

                    <div className="input-row-container">

                        <div className="input-multiple-items-container">
                            <div className="input-row-item">
                                <label className="gray-small-label">Sum prod price</label>
                                <Input addonAfter={"€"} disabled />
                            </div>
                        </div>

                    </div>
                </div>
            </TabPane>
            <TabPane tab="Logistic prices" key="4">
                <div className="carrier-detail-tab-container">
                    <div className="input-row-container">

                        <div className="input-multiple-items-container">
                            <div className="input-row-item spacing">
                                <label className="gray-small-label">Log sales list price</label>
                                <Input addonAfter={"%"} />
                            </div>
                            <div className="input-row-item">
                                <label className="gray-small-label"></label>
                                <Input addonAfter={"€"} />
                            </div>
                        </div>

                        <div className="input-multiple-items-container">
                            <div className="input-row-item spacing">
                                <label className="gray-small-label">Log Buffer</label>
                                <Input addonAfter={"%"} />
                            </div>
                            <div className="input-row-item">
                                <label className="gray-small-label"></label>
                                <Input addonAfter={"PCS"} />
                            </div>
                        </div>

                    </div>

                    <div className="input-row-container">

                        <div className="input-multiple-items-container">
                            <div className="input-row-item spacing">
                                <label className="gray-small-label">Total nr</label>
                                <Input addonAfter={"%"} />
                            </div>
                            <div className="input-row-item">
                                <label className="gray-small-label"></label>
                                <Input addonAfter={"PCS"} />
                            </div>
                        </div>

                        <div className="input-multiple-items-container">
                            <div className="input-row-item">
                                <label className="gray-small-label">Sum log price</label>
                                <Input addonAfter={"€"} />
                            </div>
                        </div>

                    </div>

                    <div className="input-row-container">

                        <div className="input-multiple-items-container">
                            <div className="input-row-item">
                                <label className="gray-small-label">Sum log price/store</label>
                                <Input addonAfter={"€"} disabled />
                            </div>
                        </div>

                    </div>
                </div>
            </TabPane>
        </Tabs>        
    </Modal>
    );
}