import { Button, DatePicker, Divider, Drawer, Input, Modal, Progress, Select, Space, Table, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { FooterComponent } from '../components/footer-component';
import HeaderMenu from '../components/header-component';
import { campaignsBreadCrumb } from '../data/breadcrumb-data';
import { CopyOutlined, LockOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { QuotationTriStatus } from './campaigns';
import filterIcon from '../resources/images/filtr_icon_white.svg';
import closeIcon from '../resources/images/close_icon_white.svg';
import { calcPercentageDays, getOfferExpiration } from '../tools/progressbar-duration-helper';

const { Option } = Select;
const { confirm } = Modal;

const quotations = [
    {
        id: 1,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 4, 10)),
        status: "Ordered",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 21,
        draft: 10,
        ordered: 60,
    },
    {
        id: 2,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Reserved",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 3,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Reserved",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 7,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 5, 10)),
        status: "Reserved",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 8,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 4, 10)),
        status: "Reserved",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 9,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 10,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 11,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 4, 1)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 12,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 13,
        pno: 'P2201001',
        campaignId: 1,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 4,
        pno: '',
        campaignId: 2,
        name: "Albert opening Moskevská",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Draft",
        ams: "Lukáš Novotný",
        amsColor: "#649cbd",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 5,
        pno: '',
        campaignId: 3,
        name: "HostessTest",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 4, 10)),
        status: "Reserved",
        ams: "David Voráček",
        amsColor: "#bd8365",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 6,
        pno: '',
        campaignId: 3,
        name: "HostessTest",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Reserved",
        ams: "David Voráček",
        amsColor: "#bd8365",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 14,
        pno: 'P2201001',
        campaignId: 6,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 4, 1)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Ordered",
        ams: "Lucie Straková",
        amsColor: "#b4bd65",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 15,
        pno: 'P2201001',
        campaignId: 6,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Cancelled",
        ams: "Lucie Straková",
        amsColor: "#b4bd65",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 16,
        pno: 'P2201001',
        campaignId: 6,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Cancelled",
        ams: "Lucie Straková",
        amsColor: "#b4bd65",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
    {
        id: 17,
        pno: 'P2201001',
        campaignId: 6,
        name: "AC Marca - Sanytol fitness",
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 6, 10)),
        status: "Cancelled",
        ams: "Lucie Straková",
        amsColor: "#b4bd65",
        total: "50 000",
        currency: "CZK",
        description: "Description",
        reserved: 57,
        draft: 37,
        ordered: 0,
    },
]

interface TypeColor {
    campaignId: number;
    color: string;
}

enum colors {
    purple = "#531dab",
    geekblue = "#1d39c4",
    blue = "#096dd9",
    cyan = "#08979c",
}

export function QuotationList() {
    const [tableOptions, setTableOptions] = useState({ pageSize: 50, showSizeChanger: false, total: 1, current: 1, loading: false });
    const [newQuotationModal, setNewQuotationModal] = useState(false);
    const [quotationRowCollor, setQuotationRowCollor] = useState([] as TypeColor[]);
    const [quotationFilterDrawerVisible, setQuotationFilterDrawerVisible] = useState(false);
    const [newClientModal, setNewClientModal] = useState(false);
    const [newCampaignLock, setNewCampaignLock] = useState(true);
    const [selectCampaignDropdownVisible, setSelectCampaignDropdownVisible] = useState(false);

    useEffect(() => {
        if (quotationRowCollor.length < 1) {
            setupQuotationRowColors();
        }
    });
    // events
    const handleChangePaginationSize = (pageSize: number) => {
        setTableOptions({ ...tableOptions, pageSize: pageSize });
    };

    const handleShowNewQuotationModal = (visible: boolean) => {
        setNewQuotationModal(visible);
    }

    const handleShowQuotationFilterDrawer = (quotationFilterDrawerVisible: boolean) => {
        setQuotationFilterDrawerVisible(quotationFilterDrawerVisible);
    };

    const handleShowNewClientModal = (visibility: boolean) => {
        setNewClientModal(visibility);
    }

    const handleNewCampaignInputs = (disabled: boolean) => {
        setSelectCampaignDropdownVisible(false);
        setNewCampaignLock(disabled);
    }

    const campaignHasQuotationOrdered = (campaignId: number) => {
        const quotation = quotations.filter(x => x.campaignId == campaignId)?.filter(y => y.status.toLowerCase() == "ordered");

        if (quotation.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const setupQuotationRowColors = () => {
        const campaigns = Array.from(new Set(quotations.map(x => x.campaignId)));
        let colorOrder = 0;

        for (const campaign of campaigns) {

            let typeColorItem = {} as TypeColor;
            typeColorItem.campaignId = campaign;
            typeColorItem.color = Object.entries(colors)[colorOrder][1];
            setQuotationRowCollor(currentArray => [...currentArray, typeColorItem]);

            if (colorOrder == 3) {
                colorOrder = 0;
            } else {
                colorOrder++;
            }
        }
    };

    const getQuotationListColoRow = (campaignId: number) => {
        const color = quotationRowCollor.find(x => x.campaignId == campaignId);
        if (color) {
            return color.color;
        } else {
            return "#aaa";
        }
    }

    const handleDeleteQuotationModal = () => {
        confirm({
            title: "Are you sure delete subcarrier",
            icon: <ExclamationCircleOutlined />,
            content: (<>Text</>),
            okText: "Yes",
            okType: 'danger',
            cancelText: "No",
            centered: true,
            maskClosable: true,
            onOk: async () => {
                console.log("Ok");
            },
        });
    }

    return (
        <div className="page-content">
            <HeaderMenu title="Quotation list" breadCrumbData={campaignsBreadCrumb} />

            <div className="filter-buttons">
                {!quotationFilterDrawerVisible &&
                    <img className="quotation-filter-icon" src={filterIcon} alt="" onClick={() => handleShowQuotationFilterDrawer(!quotationFilterDrawerVisible)} />}
                {quotationFilterDrawerVisible &&
                    <img className="quotation-filter-icon" src={closeIcon} alt="" onClick={() => handleShowQuotationFilterDrawer(!quotationFilterDrawerVisible)} />}
            </div>

            <div className="page-body">
                <Drawer title="Quotation filter" placement="right" width={360} onClose={() => handleShowQuotationFilterDrawer(false)} visible={quotationFilterDrawerVisible}>
                    <div className="filter-container">

                        <div className="filter-item">
                            <label className="gray-small-label">Name</label>
                            <Input />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Client</label>
                            <Input />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Client contact</label>
                            <Input />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Status</label>
                            <Select allowClear showSearch
                                filterOption={(input, option) =>
                                    option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }>
                                <Option value="1">Ordered</Option>
                                <Option value="2">Offer</Option>
                                <Option value="3">Reserved</Option>
                            </Select>
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Date from</label>
                            <DatePicker />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Date to</label>
                            <DatePicker />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Total</label>
                            <Input />
                        </div>

                        <div className="drawer-button-container">
                            <Button className="custom-button primary-button" type="primary" onClick={() => handleShowQuotationFilterDrawer(false)}>Search</Button>
                            <Button className="custom-button default-button" type="default" onClick={() => handleShowQuotationFilterDrawer(false)}>Reset</Button>
                            <Button className="custom-button default-button" type="default" onClick={() => handleShowQuotationFilterDrawer(false)}>Cancel</Button>
                        </div>
                    </div>
                </Drawer>

                <Table className={`scrollable-table table-nowrap custom-pagination quotations-table`} dataSource={quotations}
                    rowKey={quotation => `${quotation.id}`} scroll={{ x: true, y: '100%' }} pagination={tableOptions} loading={tableOptions.loading}
                    rowClassName={(x) => `campaign-row ${x.status.toLowerCase().replace(/\s/g, "") === 'ordered' && 'quotation-accepted-row'}`}
                    style={{}}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="pagination-row">
                                <Table.Summary.Cell className="summary-cell" index={0} colSpan={12}>
                                    <div className="campaign-legend-container">
                                        <Button className="custom-button" type="primary" onClick={() => handleShowNewQuotationModal(true)}>New Quotation</Button>
                                        <div className="campaign-legend-icons">
                                            <div className="quotation-tri-status-legend-container">
                                                <div className="quotation-tri-status-item-legend draft"></div>
                                                <label className="campaign-legend-label">Draft</label>
                                            </div>
                                            <div className="quotation-tri-status-legend-container">
                                                <div className="quotation-tri-status-item-legend reserved"></div>
                                                <label className="campaign-legend-label">Reserved</label>
                                            </div>
                                            <div className="quotation-tri-status-legend-container">
                                                <div className="quotation-tri-status-item-legend ordered"></div>
                                                <label className="campaign-legend-label">Ordered</label>
                                            </div>
                                            <div className="quotation-tri-status-legend-container">
                                                <div className="quotation-tri-status-item-legend disabled"></div>
                                                <label className="campaign-legend-label">Disabled</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Select className="pagination-changer" value={tableOptions.pageSize} onChange={e => handleChangePaginationSize(e)} >
                                        <Option value={20}>20</Option>
                                        <Option value={50}>50</Option>
                                        <Option value={100}>100</Option>
                                    </Select>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                    columns={[
                        {
                            key: 'id',
                            title: 'P. No.',
                            fixed: 'left',
                            width: 100,
                            render: (_, record) => <label className="pno-cell">{record.pno}</label>,
                        },
                        {
                            key: 'campaignName',
                            title: () =>
                                <div className="name-error">
                                    <ExclamationCircleOutlined className="error-cell" />
                                    <label>Campaign name</label>
                                </div>,
                            render: (_, record) =>
                                <div className="name-error">
                                    {getOfferExpiration(record.offerValidityFrom, record.offerValidityTo) == "expired" ? <ExclamationCircleOutlined className="error-cell red" /> : <ExclamationCircleOutlined className="error-cell hidden" />}
                                    <div className="quotation-list-color-row-container">
                                        <label className="quotation-list-color-row" style={{ background: getQuotationListColoRow(record.campaignId) }} >{record.name}</label>
                                    </div>
                                </div>,
                        },
                        {
                            key: 'offerName',
                            title: 'Offer name',
                            render: (_, record) => <>{record.offerName}</>,
                        },
                        {
                            key: 'carrierStatus',
                            title: 'Carrier Status',
                            render: (_, record) => <QuotationTriStatus item={record} hasOrderedStatus={campaignHasQuotationOrdered(record.campaignId)} />,
                        },
                        {
                            key: 'offerValidity',
                            title: 'Offer Validity',
                            width: 200,
                            render: (_, record) =>
                                <span>
                                    {record.offerValidityFrom.format("DD.MM.YYYY")}
                                    {campaignHasQuotationOrdered(record.campaignId) &&
                                        <Progress className={`timeline-progress`} data-progress={getOfferExpiration(record.offerValidityFrom, record.offerValidityTo, record.status.toLowerCase() == "ordered" ? "ordered" : "disabled")}
                                            percent={100} showInfo={false} />}

                                    {!campaignHasQuotationOrdered(record.campaignId) &&
                                        <Progress className={`timeline-progress`} data-progress={getOfferExpiration(record.offerValidityFrom, record.offerValidityTo)}
                                            percent={calcPercentageDays(record.offerValidityFrom, record.offerValidityTo)} showInfo={false} />}

                                    {record.offerValidityTo.format("DD.MM.YYYY")}
                                </span>,
                        },
                        {
                            key: 'approved',
                            title: '',
                            align: 'center',
                            render: (_, record) =>
                                <>
                                    {campaignHasQuotationOrdered(record.campaignId) && record.status.toLowerCase() == "ordered" &&
                                        <Tag className="subcarrier-tag" color="green">Approved date: <b>03.04.2022</b></Tag>
                                    }
                                </>,
                        },
                        {
                            key: 'ams',
                            title: 'AMS',
                            render: (_, record) => <><label className="campaign-table-ams" style={{ background: record.amsColor }}>{record.ams.match(/[A-Z]/g)?.join('')}</label></>,
                        },
                        {
                            key: 'carriers',
                            title: 'Carriers',
                            align: 'right',
                            render: (_, record) => <>37</>,
                        },
                        {
                            key: 'reserved',
                            title: 'Reserved',
                            align: 'right',
                            render: (_, record) => <>{record.reserved}</>,
                        },
                        {
                            key: 'total',
                            title: 'Total',
                            align: 'right',
                            render: (_, record) =>
                                <div className="amount-cell-container">
                                    <label className="amount-cell-text">{record.total}</label>
                                    <label className="amount-cell-currency">{record.currency}</label>
                                </div>,
                        },
                        {
                            key: 'description',
                            title: 'Description',
                            align: 'right',
                            render: (_, record) => <>{record.description}</>,
                        },
                        {
                            key: 'actions',
                            title: 'Actions',
                            width: 80,
                            fixed: 'right',
                            align: 'right',
                            render: (_, record) =>
                                <div className="buttons-container table-buttons-right-align">
                                    <Link to="/quotation-detail" className="a-no-style">
                                        <Button className="custom-button primary-button" type={`${campaignHasQuotationOrdered(record.campaignId) && record.status.toLowerCase() != "ordered" ? 'default' : 'primary'}`}>Detail</Button>
                                    </Link>
                                    {campaignHasQuotationOrdered(record.campaignId) ? <LockOutlined className="common-icon" /> : <CopyOutlined className="icon" />}
                                    <DeleteOutlined className="icon red-hover" onClick={handleDeleteQuotationModal} />
                                </div>,
                        },
                    ]}
                />
            </div>
            <Modal title="New quotation"
                visible={newQuotationModal}
                onOk={() => handleShowNewQuotationModal(false)}
                onCancel={() => handleShowNewQuotationModal(false)}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Select campaign</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch open={selectCampaignDropdownVisible} onDropdownVisibleChange={(visible) => setSelectCampaignDropdownVisible(visible)}
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={() => handleNewCampaignInputs(false)} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined /> Create new campaign
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="1">Campaign 1</Option>
                            <Option value="2">Campaign 2</Option>
                            <Option value="3">Campaign 3</Option>
                            <Option value="4">Campaign 4</Option>
                            <Option value="5">Campaign 5</Option>
                            <Option value="6">Campaign 6</Option>
                            <Option value="7">Campaign 7</Option>
                            <Option value="8">Campaign 8</Option>
                            <Option value="9">Campaign 9</Option>
                            <Option value="10">Campaign 10</Option>
                        </Select>
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Campaign name</label>
                        <Input disabled={newCampaignLock} />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Company</label>
                        <Input disabled={newCampaignLock} />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <div className="card-item">
                            <label className="gray-small-label">Campaign from</label>
                            <Select dropdownClassName="dropdown-new-item" allowClear showSearch disabled={newCampaignLock}
                                filterOption={(input, option) =>
                                    option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }
                            >
                                <Option value="1">Kontakt 1</Option>
                                <Option value="2">Kontakt 2</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Campaign from contact</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch disabled={newCampaignLock}
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={() => handleShowNewClientModal(true)} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined /> Create new contact
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="1">Kontakt 1</Option>
                            <Option value="2">Kontakt 2</Option>
                        </Select>
                    </div>

                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">AMS</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch disabled={newCampaignLock}
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                        >
                            <Option value="1">Osoba 1</Option>
                            <Option value="2">Osoba 2</Option>
                        </Select>
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Date from</label>
                        <DatePicker disabled={newCampaignLock} />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Date to</label>
                        <DatePicker disabled={newCampaignLock} />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Invoice to</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch disabled={newCampaignLock}
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={() => handleShowNewClientModal(true)} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined /> Create new contact
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="1">Kontakt 1</Option>
                            <Option value="2">Kontakt 2</Option>
                        </Select>
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Currency</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch disabled={newCampaignLock}
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                        >
                            <Option value="1">Měna 1</Option>
                            <Option value="2">Měna 2</Option>
                        </Select>
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Offer name</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Date from</label>
                        <DatePicker />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Date to</label>
                        <DatePicker />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Description</label>
                        <Input />
                    </div>
                </div>
            </Modal>

            <Modal title="New client"
                wrapClassName="new-client-modal"
                visible={newClientModal}
                onOk={() => handleShowNewClientModal(false)}
                onCancel={() => handleShowNewClientModal(false)}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Name</label>
                        <Input />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Phone</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">E-mail</label>
                        <Input />
                    </div>
                </div>

            </Modal>

            <FooterComponent />
        </div>
    );

}