export class CarriersModel {
    id: number = 0;
    preset: string | null = null;
    campaignName = "";
    name = "";
    individualName: string | null = null;
    type: string | null = null;
    variant: string | null = null;
    quantity: number | null = null;
    reserved: number | null = null;
    length: number | null = null;
    partner: string | null = null;
    format: string | null = null;
    mediaPrice: number | null = null;
    mediaDiscount: number | null = null;
    productionPrice: number | null = null;
    productionDiscount: number | null = null;
    logisticPrice: number | null = null;
    logisticDiscount: number | null = null;
    status: string | null = null;
    dateFrom: moment.Moment | null = null;
    dateTo: moment.Moment | null = null;
}
