import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import './styles/index.less';
import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';

ReactDOM.render(
    <Layout style={{ minHeight: '100vh' }}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Layout>,
    document.getElementById('root')
);