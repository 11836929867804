import { Routes, Route } from "react-router-dom";
import NotFound from './pages.error/not-found';
import Login from './pages.public/login';
import { Campaigns } from './pages/campaigns';
import Home from './pages/home';
import { RootLayout } from './pages/layout';
import { QuotationDetail } from './pages/quotation-detail';
import { QuotationList } from "./pages/quotation-list";
import { ReservationList } from './pages/reservation-list';
import { ReservationManager } from "./pages/reservation-manager";
import { SubcarrierList } from "./pages/subcarriers-list";

export function App() {
    return (
        <>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<RootLayout />}>
                    <Route index element={<Home />} />
                    <Route path="campaigns" element={<Campaigns />} />
                    <Route path="quotation-detail" element={<QuotationDetail />} />
                    <Route path="reservation-list" element={<ReservationList />} />
                    <Route path="reservation-manager" element={<ReservationManager />} />
                    <Route path="quotation-list" element={<QuotationList />} />
                    <Route path="subcarrier-list" element={<SubcarrierList />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}