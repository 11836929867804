import { FooterComponent } from '../components/footer-component';
import HeaderMenu from '../components/header-component';
import { subcarrierListBreadCrumb } from '../data/breadcrumb-data';
import reservationList from '../resources/screenshots/reservation_list.png';
import reservation from '../resources/screenshots/reservation.png';
import tourplan from '../resources/screenshots/tourplan.png';
import moment from 'moment';
import { Button, Dropdown, Menu, Modal, Progress, Select, Table } from 'antd';
import { Key, useEffect, useState } from 'react';
import { EditOutlined, CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, StopOutlined, CheckCircleOutlined, CalendarOutlined, DropboxOutlined } from '@ant-design/icons';
import { calcPercentageDays, getOfferExpiration, getProgressStatus } from '../tools/progressbar-duration-helper';
import { PartnerTag } from '../components/carrier-edit-modal';

const { Option } = Select;
const { confirm } = Modal;

const stores = [
    {
        id: 1,
        partner: "Albert",
        format: "HM",
        location: "Plzeň, Písecká 972/1, 326 00",
        available: 12,
        draft: 9,
        reserved: 10,
        ordered: 9,
        quantity: 40,
    },
    {
        id: 2,
        partner: "Billa",
        format: "HM",
        location: "Česká Třebová, Litomyšlská 350",
        available: 12,
        draft: 9,
        reserved: 10,
        ordered: 9,
        quantity: 10,
    },
    {
        id: 3,
        partner: "Tesco",
        format: "HM",
        location: "Kladno, nám. Jana Masaryka 2735",
        available: 12,
        draft: 9,
        reserved: 10,
        ordered: 9,
        quantity: 10,
    },
    {
        id: 4,
        partner: "Kaufland",
        format: "HM",
        location: "Jesenice, Slovenského národního povstání 1081",
        available: 12,
        draft: 9,
        reserved: 10,
        ordered: 9,
        quantity: 10,
    },
    {
        id: 5,
        partner: "Globus",
        format: "HM",
        location: "Havířov, U Skleníků 1490/24",
        available: 12,
        draft: 9,
        reserved: 10,
        ordered: 9,
        quantity: 10,
    },
]

const subcarriers = [
    {
        id: 1,
        storeId: 1,
        type: "LAMA",
        variant: "JHXCFDV",
        available: 1,
        draft: 3,
        reserved: 3,
        ordered: 3,
        quantity: 10,
    },
    {
        id: 2,
        storeId: 2,        
        type: "LAMA",
        variant: "JHXCFDV",
        available: 1,
        draft: 3,
        reserved: 3,
        ordered: 3,
        quantity: 10,
    },
    {
        id: 3,
        storeId: 3,
        type: "LAMA",
        variant: "JHXCFDV",
        available: 1,
        draft: 3,
        reserved: 3,
        ordered: 3,
        quantity: 10,
    },
    {
        id: 4,
        storeId: 4,
        type: "LAMA",
        variant: "JHXCFDV",
        available: 1,
        draft: 3,
        reserved: 3,
        ordered: 3,
        quantity: 10,
    },
    {
        id: 5,
        storeId: 5,
        type: "LAMA",
        variant: "JHXCFDV",
        available: 1,
        draft: 3,
        reserved: 3,
        ordered: 3,
        quantity: 10,
    },
    {
        id: 6,
        storeId: 1,
        type: "SS",
        variant: "JHXCFDV",
        available: 7,
        draft: 1,
        reserved: 1,
        ordered: 1,
        quantity: 10,
    },
    {
        id: 7,
        storeId: 1,
        type: "PSS",
        variant: "JHXCFDV",
        available: 4,
        draft: 2,
        reserved: 2,
        ordered: 2,
        quantity: 10,
    },
    {
        id: 8,
        storeId: 1,
        type: "FG",
        variant: "JHXCFDV",
        available: 0,
        draft: 3,
        reserved: 4,
        ordered: 3,
        quantity: 10,
    },
]

interface TypeColor {
    campaignId: number;
    color: string;
}

enum colors {
    purple = "#531dab",
    geekblue = "#1d39c4",
    blue = "#096dd9",
    cyan = "#08979c",
}

export function SubcarrierAvailabilityStuts({ item }: any) {
    const percentage = ((item.draft + item.reserved + item.ordered) / item.quantity) * 100;
    let color = "";

    if (percentage < 50) {
        color = "availability-good";
    } else if (percentage < 75) {
        color = "availability-poor";
    } else if (percentage < 99) {
        color = "availability-low";
    } else {
        color = "availability-none";
    }

    return <Progress className={`subcarrier-progress-status`} data-progress={color} percent={percentage} showInfo={false} />;
}

export function SubcarrierList() {
    const [tableOptions, setTableOptions] = useState({ pageSize: 50, showSizeChanger: false, total: 1, current: 1, loading: false });
    const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);
    const [quotationRowCollor, setQuotationRowCollor] = useState([] as TypeColor[]);
    
    const onTableSelectChange = (selectedRowKeys: Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const handleChangePaginationSize = (pageSize: number) => {
        setTableOptions({ pageSize: pageSize, showSizeChanger: false, total: 1, current: 1, loading: false });
    };

    const getQuotationListColoRow = (campaignId: number) => {
        const color = quotationRowCollor.find(x => x.campaignId == campaignId);
        if (color) {
            return color.color;
        } else {
            return "#aaa";
        }
    }

    const calcPercentage = (item: any) => {
        const perc = ((item.draft + item.reserved + item.ordered) / item.quantity) * 100;
        return perc;
    }

    const getAvailabilityColor = (percentage: number) => {
        if (percentage < 50) {
            return "availability-good";
        } else if (percentage < 75) {
            return "availability-poor";
        } else if (percentage < 99) {
            return "availability-low";
        } else {
            return "availability-none";
        }
    }

    return (
        <div className="page-content">
            <HeaderMenu title="Subcarriers" breadCrumbData={subcarrierListBreadCrumb} />

            <div className="page-body">
                <Table className="scrollable-table table-nowrap custom-pagination" data-table="reservation-table" dataSource={stores} rowKey={store => `${store.id}`} scroll={{ x: true, y: '100%' }}
                    pagination={tableOptions} loading={tableOptions.loading} rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onTableSelectChange }}                    
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="pagination-row">
                                <Table.Summary.Cell className="summary-cell" index={0} colSpan={10}>
                                    <div className="campaign-legend-container">
                                        <Button className="custom-button" type="primary" onClick={() => /*handleShowSubcarierModal()*/console.log("TODO")}>New Carrier</Button>
                                        {selectedRowKeys.length > 0 &&
                                            <Dropdown.Button className="dropdown-custom-button dropdown-primary-button" placement="topRight" onClick={() => /*handleShowMultipleChangeStatusModal(true)*/console.log("TODO")}
                                                overlay={() =>
                                                    <Menu className="dropdown-menu-container">
                                                        <Menu.Item key="1">
                                                            <CopyOutlined className="dropdown-custom-button-icon" />Duplicate
                                                        </Menu.Item>
                                                        <Menu.Item key="2">
                                                            <DeleteOutlined className="dropdown-custom-button-icon" />Delete
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                            >
                                                Change status
                                            </Dropdown.Button>
                                        }
                                    </div>
                                    <Select className="pagination-changer" value={tableOptions.pageSize} onChange={e => handleChangePaginationSize(e)} >
                                        <Option value={20}>20</Option>
                                        <Option value={50}>50</Option>
                                        <Option value={100}>100</Option>
                                    </Select>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                        </Table.Summary>
                    )}
                    columns={[
                        {
                            key: 'partner',
                            title: 'Partner',
                            render: (_, record) =>
                                <><PartnerTag item={record} /></>,
                        },
                        {
                            key: 'format',
                            title: 'Format',
                            render: (_, record) => <>{record.format}</>,
                        },
                        {
                            key: 'location',
                            title: 'Location',
                            render: (_, record) => <>{record.location}</>                                
                        },
                        {
                            key: 'availabity',
                            title: 'Availabity',
                            align: 'right',
                            render: (_, record) => <>{record.available}</>,
                        },
                        {
                            key: 'draft',
                            title: 'Draft',
                            align: 'right',
                            render: (_, record) => <>{record.draft}</>,
                        },
                        {
                            key: 'reserved',
                            title: 'Reserved',
                            align: 'right',
                            render: (_, record) => <>{record.reserved}</>,
                        },
                        {
                            key: 'ordered',
                            title: 'Ordered',
                            align: 'right',
                            render: (_, record) => <>{record.ordered}</>,
                        },
                        {
                            key: 'quantity',
                            title: 'Quantity',
                            align: 'right',
                            width: 60,
                            render: (_, record) => <>{record.quantity}</>,
                        },
                        {
                            key: 'status',
                            title: 'Status',
                            align: 'right',
                            render: (_, record) => <></>
                        },
                        {
                            key: 'actions',
                            title: 'Actions',
                            align: 'right',
                            fixed: 'right',
                            render: (_, record) =>
                                <div className="buttons-container table-buttons-right-align">
                                    {/*<EditOutlined className="icon" onClick={() => handleShowSubcarierModal(record)} />
                                    <CopyOutlined className="icon" onClick={() => handleDuplicateCarrierModal()} />
                        <DeleteOutlined className="icon red-hover" onClick={() => handleDeleteCarrierModal()} />*/}
                                </div>,
                        },
                    ]}
                    expandedRowRender={(store) => {

                        if (subcarriers.length > 0) {
                            return <Table className="nested-table nested-quotation-table nested-package-carrier-table" dataSource={subcarriers.filter(x => x.storeId == store.id)} rowKey={rm => `${rm.id}`} pagination={false}
                                columns={[
                                    {
                                        key: 'type',
                                        title: 'Type',
                                        render: (_, record) => <>{record.type}</>,
                                    },
                                    {
                                        key: 'variant',
                                        title: 'Variant',
                                        render: (_, record) => <>{record.variant}</>,
                                    },
                                    {
                                        key: 'available',
                                        title: 'Available',
                                        align: 'right',
                                        render: (_, record) => <>{record.available}</>,
                                    },
                                    {
                                        key: 'draft',
                                        title: 'Draft',
                                        align: 'right',
                                        render: (_, record) => <>{record.draft}</>,
                                    },
                                    {
                                        key: 'reserved',
                                        title: 'Reserved',
                                        align: 'right',
                                        render: (_, record) => <>{record.reserved}</>,
                                    },
                                    {
                                        key: 'ordered',
                                        title: 'Ordered',
                                        align: 'right',
                                        render: (_, record) => <>{record.ordered}</>,
                                    },
                                    {
                                        key: 'quantity',
                                        title: 'Quantity',
                                        align: 'right',
                                        render: (_, record) => 
                                        <div className='cell-flex-right-container'>
                                            <div className="progress-status-container">
                                                <SubcarrierAvailabilityStuts item={record} />
                                                <div className="carrier-progress-label">{record.quantity}</div>
                                                </div>
                                            </div>,
                                    },
                                ]}
                            />
                        }
                        else {
                            return <>No quotation</>;
                        }
                    }}
                />
            </div>

            <FooterComponent />
        </div>
    );
}