import { Button, Collapse, DatePicker, Divider, Drawer, Dropdown, Input, InputNumber, Menu, Modal, Progress, Radio, Select, Space, Table, Tabs, Tag, Typography } from 'antd';
import { Key, useEffect, useState } from 'react';
import { FooterComponent } from '../components/footer-component';
import HeaderMenu from '../components/header-component';
import { campaignsBreadCrumb } from '../data/breadcrumb-data';
import { EditOutlined, CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, PhoneOutlined, PlusOutlined, StopOutlined, CheckCircleOutlined, CalendarOutlined, DropboxOutlined } from '@ant-design/icons';
import { CarriersModel } from '../data/carriers-model';
import summaryPreview from '../resources/screenshots/summary_preview.png'
import moment from 'moment';
import filterIcon from '../resources/images/filtr_icon_white.svg';
import closeIcon from '../resources/images/close_icon_white.svg';
import { calcPercentageDays, getOfferExpiration, getProgressStatus } from '../tools/progressbar-duration-helper';
import { CarrierEditModal, PartnerTag } from '../components/carrier-edit-modal';

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Panel } = Collapse;

const carriers: CarriersModel[] = [
    {
        id: 1,
        preset: "",
        campaignName: "AC Marca - Sanytol fitness",
        name: "3D Stojna - Lama",
        individualName: "Individual Name",
        type: "LAMA",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 4,
        length: 2,
        partner: "Albert",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 10,
        productionPrice: 21475,
        productionDiscount: 11,
        logisticPrice: 12347,
        logisticDiscount: 58,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 3, 10)),
    },
    {
        id: 2,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Shelf Stopper",
        individualName: "",
        type: "SS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Albert",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 15,
        productionPrice: 21475,
        productionDiscount: 47,
        logisticPrice: 12347,
        logisticDiscount: 36,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 3,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Shelf Stopper LED - Premiový",
        individualName: "",
        type: "PSS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 10,
        length: 2,
        partner: "Globus",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 3,
        productionPrice: 21475,
        productionDiscount: 27,
        logisticPrice: 12347,
        logisticDiscount: 45,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 4,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Podlahová grafika",
        individualName: "",
        type: "FG",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 2,
        length: 2,
        partner: "Tesco",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 25,
        productionPrice: 21475,
        productionDiscount: 44,
        logisticPrice: 12347,
        logisticDiscount: 15,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 6, 10)),
        dateTo: moment(new Date(2022, 8, 10)),
    },
    {
        id: 5,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Energy",
        individualName: "",
        type: "Energy",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Electro World",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 17,
        productionPrice: 21475,
        productionDiscount: 67,
        logisticPrice: 12347,
        logisticDiscount: 68,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 6,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "3D Stojna - Lama",
        individualName: "",
        type: "LAMA",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 6,
        length: 2,
        partner: "Penny",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 6,
        productionPrice: 21475,
        productionDiscount: 52,
        logisticPrice: 12347,
        logisticDiscount: 25,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 7,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Shelf Stopper",
        individualName: "",
        type: "SS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Kaufland",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 0,
        productionPrice: 21475,
        productionDiscount: 4,
        logisticPrice: 12347,
        logisticDiscount: 65,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 8,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Shelf Stopper LED - Premiový",
        individualName: "",
        type: "PSS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 0,
        length: 2,
        partner: "Makro",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 29,
        productionPrice: 21475,
        productionDiscount: 3,
        logisticPrice: 12347,
        logisticDiscount: 78,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 9,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Podlahová grafika",
        individualName: "",
        type: "FG",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 0,
        length: 2,
        partner: "Rossmann",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 35,
        productionPrice: 21475,
        productionDiscount: 99,
        logisticPrice: 12347,
        logisticDiscount: 37,
        status: "Ordered",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 10,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Energy",
        individualName: "",
        type: "Energy",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 10,
        length: 2,
        partner: "Albert",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 11,
        productionPrice: 21475,
        productionDiscount: 42,
        logisticPrice: 12347,
        logisticDiscount: 78,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 11,
        preset: "Package 1",
        campaignName: "Albert opening Moskevská",
        name: "Promo Package",
        individualName: "",
        type: "",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 7,
        length: 2,
        partner: "Makro",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 40,
        productionPrice: 21475,
        productionDiscount: 36,
        logisticPrice: 12347,
        logisticDiscount: 46,
        status: "Ordered",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 12,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Shelf Stopper",
        individualName: "",
        type: "SS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Albert",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 15,
        productionPrice: 21475,
        productionDiscount: 47,
        logisticPrice: 12347,
        logisticDiscount: 36,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 13,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Shelf Stopper LED - Premiový",
        individualName: "",
        type: "PSS LED",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 10,
        length: 2,
        partner: "Globus",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 3,
        productionPrice: 21475,
        productionDiscount: 27,
        logisticPrice: 12347,
        logisticDiscount: 45,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 14,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Podlahová grafika",
        individualName: "",
        type: "FG",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 2,
        length: 2,
        partner: "Tesco",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 25,
        productionPrice: 21475,
        productionDiscount: 44,
        logisticPrice: 12347,
        logisticDiscount: 15,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 15,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "Energy",
        individualName: "",
        type: "Energy",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Electro World",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 17,
        productionPrice: 21475,
        productionDiscount: 67,
        logisticPrice: 12347,
        logisticDiscount: 68,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 16,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 3,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 19,
        productionPrice: 21475,
        productionDiscount: 82,
        logisticPrice: 12347,
        logisticDiscount: 2,
        status: "Cancelled",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 17,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 8,
        productionPrice: 21475,
        productionDiscount: 25,
        logisticPrice: 12347,
        logisticDiscount: 32,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 18,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 4,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 1,
        productionPrice: 21475,
        productionDiscount: 36,
        logisticPrice: 12347,
        logisticDiscount: 68,
        status: "Cancelled",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 19,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 1,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 37,
        productionPrice: 21475,
        productionDiscount: 64,
        logisticPrice: 12347,
        logisticDiscount: 45,
        status: "Cancelled",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 20,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 24,
        productionPrice: 21475,
        productionDiscount: 7,
        logisticPrice: 12347,
        logisticDiscount: 94,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 21,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 5,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 14,
        productionPrice: 21475,
        productionDiscount: 55,
        logisticPrice: 12347,
        logisticDiscount: 82,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 22,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 3,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 34,
        productionPrice: 21475,
        productionDiscount: 69,
        logisticPrice: 12347,
        logisticDiscount: 64,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 23,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 0,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 2,
        productionPrice: 21475,
        productionDiscount: 20,
        logisticPrice: 12347,
        logisticDiscount: 43,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 24,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 18,
        productionPrice: 21475,
        productionDiscount: 93,
        logisticPrice: 12347,
        logisticDiscount: 20,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 25,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 10,
        productionPrice: 21475,
        productionDiscount: 9,
        logisticPrice: 12347,
        logisticDiscount: 65,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 26,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 7,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 28,
        productionPrice: 21475,
        productionDiscount: 10,
        logisticPrice: 12347,
        logisticDiscount: 78,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 27,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 1,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 37,
        productionPrice: 21475,
        productionDiscount: 35,
        logisticPrice: 12347,
        logisticDiscount: 17,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 28,
        preset: "",
        campaignName: "Albert opening Moskevská",
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 1,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 37,
        productionPrice: 21475,
        productionDiscount: 35,
        logisticPrice: 12347,
        logisticDiscount: 17,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
]

const carriersPackage = [
    {
        id: 1,
        packageId: 1,
        type: "LAMA",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 3, 10)),
        dateTo: moment(new Date(2022, 4, 9)),
    },
    {
        id: 2,
        packageId: 1,
        type: "LAMA",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 4, 10)),
        dateTo: moment(new Date(2022, 5, 10)),
    },
    {
        id: 3,
        packageId: 1,
        type: "SS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 4,
        packageId: 1,
        type: "SS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 5,
        packageId: 1,
        type: "PSS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 6,
        packageId: 1,
        type: "FG",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 7,
        packageId: 1,
        type: "PSS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 8,
        packageId: 1,
        type: "PSS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 9,
        packageId: 1,
        type: "Energy",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
]

const campaigns = [
    {
        id: 1,
        pno: 'P2201001',
        name: "AC Marca - Sanytol fitness",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
        status: "Finished",
        campaignFrom: "AC MARCA Czech Republic s.r.o.",
        invoiceTo: "AC MARCA Czech Republic s.r.o.",
        ams: "Michal Urbánek",
        amsColor: "#66bd97",
        total: "50 000",
        currency: "CZK",
        billed: 1,
        invoice: "INV",
        company: "EMP CZ",
        quotations: 25,
        qtsApproved: true,
        incomplete: false,
    },
]

const subCarriers = [
    {
        storeId: 19247,
        format: "HM",
        name: "Plzeň",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Reserved",
        quantity: "1",
        type: "SS",
    },
    {
        storeId: 162,
        format: "HM",
        name: "Brno",
        city: "Brno",
        street: "U dálnice 744, 664 42, Brno",
        size: "0K",
        closedFrom: "-",
        closedTo: "15. 05. 2022",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "FG",
    },
    {
        storeId: 195,
        format: "HM",
        name: "Praha - Nové Butovice",
        city: "Praha - Nové Butovice",
        street: "Radlická 117, 153 00, Praha 5",
        size: "0K",
        closedFrom: "-",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "PSS",
    },
    {
        storeId: 829,
        format: "HM",
        name: "Praha - Arkády",
        city: "Praha - Arkády",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "0K",
        closedFrom: "-",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "Typ1",
    },
    {
        storeId: 348,
        format: "HM",
        name: "Vyškov",
        city: "Vyškov",
        street: "U Jandovky 2, 682 01, Vyškov",
        size: "0K",
        closedFrom: "-",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        status: "Draft",
        quantity: "1",
        type: "Typ2",
    },
];

const quotations = [
    {
        id: 1,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 5, 10)),
        approveDate: moment(new Date(2022, 2, 10)),
        reserved: "12",
        description: "Description here",
        total: "50 000",
        currency: "CZK",
    },
]

interface TypeColor {
    type: string;
    color: string;
}

enum colors {
    purple = "#531dab",
    geekblue = "#1d39c4",
    blue = "#096dd9",
    cyan = "#08979c",
}

export function QuotationDetail() {
    const [tableOptions, setTableOptions] = useState({ pageSize: 50, showSizeChanger: false, total: 1, current: 1, loading: false });
    const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);
    const [carriersDrawerData, setCarriersDrawerData] = useState({ visibility: false, data: new CarriersModel() });
    const [editCampaignModal, setEditCampaignModal] = useState({ visibility: false, isLoading: false });
    const [newClientModal, setNewClientModal] = useState({ visibility: false, isLoading: false });
    const [editQuotationModal, setEditQuotationModal] = useState({ visibility: false, isLoading: false });
    const [multiEditSubcarrierModal, setMultiEditSubcarrierModal] = useState({ visibility: false, isLoading: false });
    const [subcarrierSelectedRowKeys, setSubcarrierSelectedRowKeys] = useState([] as Key[]);
    const [selectedTabKey, setSelectedTabKey] = useState("1");
    const [summaryPreviewModal, setSummaryPreviewModal] = useState(false);
    const [multipleChangeStatusModal, setMultipleChangeStatusModal] = useState(false);
    const [campaignFilterDrawerVisible, setCampaignFilterDrawerVisible] = useState(false);
    const [subcarriersTypeColor, setSubcarriersTypeColor] = useState([] as TypeColor[]);
    const [subcarrierCollapse, setSubcarrierCollapse] = useState('0');

    const [showTestModal, setShowTestModal] = useState(false);

    const handleShowFilterDrawer = (visible: boolean) => {
        setCampaignFilterDrawerVisible(visible);
    };

    const onTableSelectChange = (selectedRowKeys: Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const onSubcarrierTableSelectChange = (subcarrierSelectedRowKeys: Key[]) => {
        setSubcarrierSelectedRowKeys(subcarrierSelectedRowKeys);
    };

    const handleShowSubcarierModal = (item?: CarriersModel) => {
        setCarriersDrawerData({ visibility: true, data: item ? item : new CarriersModel() });
        setSubcarriersTypeColor([]);

        if (item) {
            setSubcarrierCollapse('0');
        } else {
            setSubcarrierCollapse('1');
        }
        
        const types = Array.from(new Set(subCarriers.map(x => x.type)));
        let colorOrder = 0;

        for (const type of types) {

            let typeColorItem = {} as TypeColor;
            typeColorItem.type = type;
            typeColorItem.color = Object.entries(colors)[colorOrder][1];
            setSubcarriersTypeColor(currentArray => [...currentArray, typeColorItem]);

            if (colorOrder == 3) {
                colorOrder = 0;
            } else {
                colorOrder++;
            }
        }
    };

    const getCarrierTypeColor = (type: string) => {
        const color = subcarriersTypeColor.find(x => x.type == type);
        if (color) {
            return color.color;
        } else {
            return "#aaa";
        }
    }

    const handleCloseSubcarierModal = () => {
        setSubcarrierSelectedRowKeys([]);
        setCarriersDrawerData({ visibility: false, data: new CarriersModel() });
    };

    const handleShowMultiEditSubcarrierModal = (visible: boolean) => {
        setMultiEditSubcarrierModal({ ...multiEditSubcarrierModal, visibility: visible });
    };

    const handleChangePaginationSize = (pageSize: number) => {
        setTableOptions({ pageSize: pageSize, showSizeChanger: false, total: 1, current: 1, loading: false });
    };

    const handleShowEditCampaignModal = (visible: boolean) => {
        setEditCampaignModal({ ...editCampaignModal, visibility: visible });
    }

    const handleShowNewClientModal = (visible: boolean) => {
        setNewClientModal({ ...newClientModal, visibility: visible });
    }

    const handleShowEditQuotationClientModal = (visible: boolean) => {
        setEditQuotationModal({ ...editQuotationModal, visibility: visible });
    }

    const handleShowEditQuotationModal = (visible: boolean) => {
        setEditQuotationModal({ ...editQuotationModal, visibility: visible })
    }

    const handleShowSummaryPreviewModal = (visible: boolean) => {
        setSummaryPreviewModal(visible);
    }

    const handleShowMultipleChangeStatusModal = (visible: boolean) => {
        setMultipleChangeStatusModal(visible);
    }

    const handleDeleteSubcarrierModal = () => {
        confirm({
            title: "Are you sure delete subcarrier",
            icon: <ExclamationCircleOutlined />,
            content: (<>Text</>),
            okText: "Yes",
            okType: 'danger',
            cancelText: "No",
            centered: true,
            maskClosable: true,
            onOk: async () => {
                console.log("Ok");
            },
        });
    }

    const handleDeleteCarrierModal = () => {
        confirm({
            title: "Are you sure delete carrier",
            icon: <ExclamationCircleOutlined />,
            content: (<>Text</>),
            okText: "Yes",
            okType: 'danger',
            cancelText: "No",
            centered: true,
            maskClosable: true,
            onOk: async () => {
                console.log("Ok");
            },
        });
    }

    const handleDuplicateCarrierModal = () => {
        confirm({
            title: "Are you sure duplicate carrier",
            icon: <ExclamationCircleOutlined />,
            content: (<>Text</>),
            okText: "Yes",
            cancelText: "No",
            centered: true,
            maskClosable: true,
            onOk: async () => {
                console.log("Ok");
            },
        });
    }

    const handleApproveQuotationModal = () => {
        confirm({
            title: "Are you sure...",
            icon: <ExclamationCircleOutlined />,
            content: (<>Text</>),
            okText: "Yes",
            cancelText: "No",
            centered: true,
            maskClosable: true,
            onOk: async () => {
                console.log("Ok");
            },
        });
    }

    const handleChangeTab = (key: string) => {
        setSelectedTabKey(key);
    }

    const getCarriersPackageData = (carrier: any) => {
        const filterData = carriersPackage.filter(x => x.type == carrier.type)
        if(filterData.length > 0) {
            return filterData;
        } else {
            return carriersPackage;
        }
    }

    const handleSubcarrierCollapse = (key: any) => {
        if(subcarrierCollapse == '0') {
            setSubcarrierCollapse('1');
        } else {
            setSubcarrierCollapse('0');
        }        
    }

    return (
        <div className="page-content">
            <HeaderMenu title="Quotation details" breadCrumbData={campaignsBreadCrumb} />

            <div className="filter-buttons">
                {!campaignFilterDrawerVisible &&
                    <img className="filter-icon" src={filterIcon} alt="" onClick={() => handleShowFilterDrawer(true)} />}
                {campaignFilterDrawerVisible &&
                    <img className="filter-icon" src={closeIcon} alt="" onClick={() => handleShowFilterDrawer(false)} />}
            </div>

            <Drawer title="Filter" placement="right" width={360} onClose={() => handleShowFilterDrawer(false)} visible={campaignFilterDrawerVisible}>
                <div className="filter-container">
                    <div className="filter-item">
                        <label className="gray-small-label">PNo</label>
                        <Input />
                    </div>

                    <div className="filter-item">
                        <label className="gray-small-label">Name</label>
                        <Input />
                    </div>

                    <div className="filter-item">
                        <label className="gray-small-label">Date from</label>
                        <DatePicker />
                    </div>

                    <div className="filter-item">
                        <label className="gray-small-label">Date to</label>
                        <DatePicker />
                    </div>

                    <div className="filter-item">
                        <label className="gray-small-label">Status</label>
                        <Select>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                        </Select>
                    </div>

                    <div className="drawer-button-container">
                        <Button className="custom-button primary-button" type="primary" onClick={() => handleShowFilterDrawer(false)}>Search</Button>
                        <Button className="custom-button default-button" type="default" onClick={() => handleShowFilterDrawer(false)}>Reset</Button>
                        <Button className="custom-button default-button" type="default" onClick={() => handleShowFilterDrawer(false)}>Cancel</Button>
                    </div>
                </div>
            </Drawer>

            <div className="page-body">
                <div className="quotation-detail-container" >

                    <Table className={`table-nowrap campaigns-table  quotation-detail-table`} dataSource={campaigns} rowKey={campaign => `${campaign.id}`} pagination={false}
                        rowClassName={`campaign-row`}
                        columns={[
                            {
                                key: 'pno',
                                title: 'P.No.',
                                fixed: 'left',
                                width: 100,
                                render: (_, record) => <label className="pno-cell">{record.pno}</label>,
                            },
                            {
                                key: 'name',
                                title: () =>
                                    <div className="name-error">
                                        <ExclamationCircleOutlined className="error-cell" />
                                        <label>Name</label>
                                    </div>,
                                render: (_, record) =>
                                    <div className="name-error">
                                        {record.incomplete ? <ExclamationCircleOutlined className="error-cell red" /> : <ExclamationCircleOutlined className="error-cell hidden" />}
                                        <label>{record.name}</label>
                                    </div>,
                            },
                            {
                                key: 'interval',
                                title: 'Interval',
                                render: (_, record) => <>{record.dateFrom.format("DD.MM.YYYY")} <Progress className={`timeline-progress`} data-progress={record.status.toLowerCase()} percent={calcPercentageDays(record.dateFrom, record.dateTo)} showInfo={false} /> {record.dateTo.format("DD.MM.YYYY")}</>,
                            },
                            {
                                key: 'status',
                                title: 'Status',
                                render: (_, record) =>
                                    <div className="campaign-status-container">
                                        <label className={`campaign-status ${record.status.toLowerCase()}`} >{record.status}</label>
                                    </div>,
                            },
                            {
                                key: 'campaignFrom',
                                title: 'Campaign From',
                                render: (_, record) => <>{record.campaignFrom}</>,
                            },
                            {
                                key: 'invoiceTo',
                                title: 'Invoice To',
                                render: (_, record) => <>{record.invoiceTo}</>,
                            },
                            {
                                key: 'ams',
                                title: 'AMS',
                                render: (_, record) => <><label className="campaign-table-ams" style={{ background: record.amsColor }}>{record.ams.match(/[A-Z]/g)?.join('')}</label></>,
                            },
                            {
                                key: 'total',
                                title: 'Total',
                                align: 'right',
                                render: (_, record) =>
                                    <div className="amount-cell-container">
                                        <label className="amount-cell-text">{record.total}</label>
                                        <label className="amount-cell-currency">{record.currency}</label>
                                    </div>,
                            },
                            {
                                key: 'invoice',
                                title: 'Invoice',
                                render: (_, record) =>
                                    <div className="campaign-invoice-container">
                                        <label className={`campaign-invoice ${record.invoice.toLowerCase().replace(/\s/g, "")}`} >{record.invoice}</label>
                                    </div>,
                            },
                            {
                                key: 'company',
                                title: 'Company',
                                width: 80,
                                render: (_, record) =>
                                    <div className="campaign-company-container">
                                        <label className={`campaign-company ${record.company.toLowerCase().replace(/\s/g, "")}`} >{record.company}</label>
                                    </div>,
                            },
                            {
                                key: 'actions',
                                title: 'Actions',
                                align: 'right',
                                render: (_, record) => <div className="buttons-container table-buttons-right-align">
                                    <PhoneOutlined className="icon" />
                                    <EditOutlined className="icon" onClick={() => handleShowEditCampaignModal(true)} />
                                </div>,
                            },
                        ]}
                        defaultExpandAllRows={true}
                        expandedRowRender={() => {

                            if (quotations.length > 0) {
                                return <Table className="nested-table nested-quotation-table" dataSource={quotations} rowKey={rm => `${rm.id}`} pagination={false}
                                    columns={[
                                        {
                                            key: 'id',
                                            title: 'ID',
                                            fixed: 'left',
                                            width: 100,
                                            render: (_, record) => <label className="pno-cell">{record.id}</label>,
                                        },
                                        {
                                            key: 'offerName',
                                            title: 'Offer name',
                                            render: (_, record) => <>{record.offerName}</>,
                                        },
                                        {
                                            key: 'offerValidity',
                                            title: 'Offer Validity',
                                            render: (_, record) =>
                                                <span>
                                                    {record.offerValidityFrom.format("DD.MM.YYYY")}
                                                    <Progress className={`timeline-progress`} data-progress={getOfferExpiration(record.offerValidityFrom, record.offerValidityTo)} percent={calcPercentageDays(record.offerValidityFrom, record.offerValidityTo)} showInfo={false} />
                                                    {record.offerValidityTo.format("DD.MM.YYYY")}
                                                </span>,
                                        },
                                        {
                                            key: 'approveDate',
                                            title: 'Approve Date',
                                            render: (_, record) => <>{record.approveDate.format("DD.MM.YYYY")}</>,
                                        },
                                        {
                                            key: 'carriers',
                                            title: 'Carriers',
                                            align: 'right',
                                            render: (_, record) => <>37</>,
                                        },
                                        {
                                            key: 'reserved',
                                            title: 'Reserved',
                                            align: 'right',
                                            render: (_, record) => <>{record.reserved}</>,
                                        },
                                        {
                                            key: 'discount',
                                            title: 'Discount',
                                            align: 'right',
                                            render: (_, record) =>
                                                <div className="amount-cell-container">
                                                    <label className="amount-cell-text">15 414</label>
                                                    <label className="amount-cell-currency">{record.currency}</label>
                                                </div>,
                                        },
                                        {
                                            key: 'total',
                                            title: 'Total',
                                            align: 'right',
                                            render: (_, record) =>
                                                <div className="amount-cell-container">
                                                    <label className="amount-cell-text">{record.total}</label>
                                                    <label className="amount-cell-currency">{record.currency}</label>
                                                </div>,
                                        },
                                        {
                                            key: 'description',
                                            title: 'Description',
                                            render: (_, record) => <>{record.description}</>,
                                        },
                                        {
                                            key: 'actions',
                                            title: 'Actions',
                                            width: 80,
                                            fixed: 'right',
                                            align: 'right',
                                            render: (_, record) =>
                                                <div className="buttons-container table-buttons-right-align">
                                                    <EditOutlined className="icon" onClick={() => handleShowEditQuotationClientModal(true)} />
                                                </div>,
                                        },
                                    ]}
                                />
                            }
                            else {
                                return <>No quotation</>;
                            }
                        }}
                    />

                    <div className="quotation-detail-table-title-container">
                        <label className="quotation-detail-table-title">Carriers</label>
                        <div className="quotation-status-button-container">
                            <span style={{ display: 'none' }}>
                                <label className="gray-small-label quotation-detail-summary-status-label">Quotation status:</label>
                                <Radio.Group className="quotation-status-radiobutton-container" defaultValue="1" buttonStyle="solid">
                                    <Radio.Button className="quotation-status-radiobutton draft" value="1">Draft</Radio.Button>
                                    <Radio.Button className="quotation-status-radiobutton reserve" value="2">Reserve</Radio.Button>
                                    <Radio.Button className="quotation-status-radiobutton order" value="3">Order</Radio.Button>
                                </Radio.Group>
                            </span>

                            <Button className="custom-button quoation-approve-button" type="default" onClick={() => handleApproveQuotationModal()}>Approve quotation</Button>
                            <Button className="custom-button dark-blue default-button" type="default" onClick={() => handleShowSummaryPreviewModal(true)}>Summary preview</Button>
                        </div>
                    </div>
                </div>

                <Table className="scrollable-table table-nowrap custom-pagination" data-table="carriers-table" dataSource={carriers} rowKey={carriers => `${carriers.id}`} scroll={{ x: true, y: '100%' }}
                    pagination={tableOptions} loading={tableOptions.loading} rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onTableSelectChange }}
                    rowClassName={(x) => `${x.preset && 'carrier-package-row'} ${x.status?.toLowerCase() == "cancelled" && 'carrier-cancelled-row'}`}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="quotation-detail-table-summary-ammount-row">
                                <Table.Summary.Cell index={0} colSpan={8}></Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={1} >280</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={2} >425 196 CZK</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={3} ></Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={4} >425 196 CZK</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={5} ></Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={6} >425 196 CZK</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={7} ></Table.Summary.Cell>
                                <Table.Summary.Cell index={8} colSpan={2} ></Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row className="pagination-row">
                                <Table.Summary.Cell className="summary-cell" index={0} colSpan={16}>
                                    <div className="campaign-legend-container">
                                        <Button className="custom-button" type="primary" onClick={() => handleShowSubcarierModal()}>New Carrier</Button>
                                        {selectedRowKeys.length > 0 &&
                                            <Dropdown.Button className="dropdown-custom-button dropdown-primary-button" placement="topRight" onClick={() => handleShowMultipleChangeStatusModal(true)}
                                                overlay={() =>
                                                    <Menu className="dropdown-menu-container">
                                                        <Menu.Item key="1">
                                                            <CopyOutlined className="dropdown-custom-button-icon" />Duplicate
                                                        </Menu.Item>
                                                        <Menu.Item key="2">
                                                            <DeleteOutlined className="dropdown-custom-button-icon" />Delete
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                            >
                                                Change status
                                            </Dropdown.Button>
                                        }
                                    </div>
                                    <Select className="pagination-changer" value={tableOptions.pageSize} onChange={e => handleChangePaginationSize(e)} >
                                        <Option value={20}>20</Option>
                                        <Option value={50}>50</Option>
                                        <Option value={100}>100</Option>
                                    </Select>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                        </Table.Summary>
                    )}
                    columns={[
                        {
                            key: 'name',
                            title: () =>
                                <div className="name-error">
                                    <ExclamationCircleOutlined className="error-cell" />
                                    <label>Offer name</label>
                                </div>,
                            fixed: 'left',
                            sorter: (a, b) => a.name.localeCompare(b.name),
                            render: (_, record) =>
                                <div className="name-error">
                                    <span className='carrier-name-icon'>
                                        {getOfferExpiration(record.dateFrom, record.dateTo) == "expired" ? <ExclamationCircleOutlined className="error-cell red" /> : (record.preset && <DropboxOutlined className="carrier-package-icon" />)}
                                    </span>
                                    <label className={`pno-cell ${record.individualName && 'carriers-individual-name'}`}>{record.individualName ? record.individualName : record.name}</label>
                                </div>,
                        },
                        {
                            key: 'interval',
                            title: 'Interval',
                            render: (_, record) =>
                                <span>
                                    {record.dateFrom?.format("DD.MM.YYYY")}
                                    <Progress className={`timeline-progress`}
                                        data-progress={getProgressStatus(record.dateFrom, record.dateTo, record.status?.toLowerCase() == "cancelled" && true)}
                                        percent={record.status?.toLowerCase() == "cancelled" ? 0 : calcPercentageDays(record.dateFrom, record.dateTo)} showInfo={false} />
                                    {record.dateTo?.format("DD.MM.YYYY")}
                                </span>,
                        },
                        {
                            key: 'partner',
                            title: 'Partner',
                            render: (_, record) =>
                                <><PartnerTag item={record} /></>,
                        },
                        {
                            key: 'format',
                            title: 'Format',
                            render: (_, record) => <>{record.format}</>,
                        },
                        {
                            key: 'type',
                            title: 'Type',
                            render: (_, record) => <>{record.preset ? record.preset : record.type}</>,
                        },
                        {
                            key: 'variant',
                            title: 'Variant',
                            render: (_, record) => <>{record.variant}</>,
                        },
                        {
                            key: 'quantity',
                            title: 'Quantity',
                            align: 'right',
                            width: 60,
                            render: (_, record) => <>{record.quantity}</>,
                        },
                        {
                            key: 'mediaPrice',
                            title: 'Media Price',
                            align: 'right',
                            render: (_, record) => <label className="bold-text">{record.mediaPrice?.toLocaleString()} CZK</label>,
                        },
                        {
                            key: 'mediaDiscount',
                            title: 'M Dis',
                            align: 'center',
                            className: 'table-no-padding',
                            width: 50,
                            render: (_, record) => <div className="carrier-discount-cell" style={{ background: `rgba(177, 177, 177,${record.mediaDiscount ? record.mediaDiscount / 100 * 1.5 : 0})`, }}>{record.mediaDiscount} %</div>,
                        },
                        {
                            key: 'productionPrice',
                            title: 'Production Price',
                            align: 'right',
                            render: (_, record) => <label className="bold-text">{record.productionPrice?.toLocaleString()} CZK</label>,
                        },
                        {
                            key: 'productionDiscount',
                            title: 'P Dis',
                            align: 'center',
                            className: 'table-no-padding',
                            width: 50,
                            render: (_, record) => <div className="carrier-discount-cell" style={{ background: `rgba(177, 177, 177,${record.mediaDiscount ? record.mediaDiscount / 100 * 1.5 : 0})`, }}>{record.productionDiscount} %</div>,
                        },
                        {
                            key: 'logisticPrice',
                            title: 'Logistic Price',
                            align: 'right',
                            render: (_, record) => <label className="bold-text">{record.logisticPrice?.toLocaleString()} CZK</label>,
                        },
                        {
                            key: 'logisticDiscount',
                            title: 'L Dis',
                            align: 'center',
                            className: 'table-no-padding',
                            width: 50,
                            render: (_, record) => <div className="carrier-discount-cell" style={{ background: `rgba(177, 177, 177,${record.mediaDiscount ? record.mediaDiscount / 100 * 1.5 : 0})`, }}>{record.logisticDiscount} %</div>,
                        },
                        {
                            key: 'status',
                            title: 'Status',
                            align: 'right',
                            render: (_, record) =>
                                <div className="carrier-status-container">
                                    <label className={`quotation-status ${record.status?.toLowerCase()}`} >{record.status}</label>
                                    <Dropdown.Button className="dropdown-custom-button dropdown-custom-button-carrier-status" data-button={record.status?.toLowerCase()} placement="topRight"
                                        overlay={() =>
                                            <Menu className="dropdown-menu-container">
                                                <Menu.Item key="1">
                                                    <EditOutlined className="dropdown-custom-button-icon" />Draft
                                                </Menu.Item>
                                                <Menu.Item key="2">
                                                    <CalendarOutlined className="dropdown-custom-button-icon" />Reserved
                                                </Menu.Item>
                                                <Menu.Item key="3">
                                                    <CheckCircleOutlined className="dropdown-custom-button-icon" />Ordered
                                                </Menu.Item>
                                                <Menu.Item key="4">
                                                    <StopOutlined className="dropdown-custom-button-icon" />Cancel
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        Actions
                                    </Dropdown.Button>
                                </div>,
                        },
                        {
                            key: 'actions',
                            title: 'Actions',
                            align: 'right',
                            fixed: 'right',
                            render: (_, record) =>
                                <div className="buttons-container table-buttons-right-align">
                                    <EditOutlined className="icon" onClick={() => handleShowSubcarierModal(record)} />
                                    <CopyOutlined className="icon" onClick={() => handleDuplicateCarrierModal()} />
                                    <DeleteOutlined className="icon red-hover" onClick={() => handleDeleteCarrierModal()} />
                                </div>,
                        },
                    ]}
                    expandedRowRender={(carrier) => {

                        if (quotations.length > 0) {
                            return <Table className="nested-table nested-quotation-table nested-package-carrier-table" dataSource={getCarriersPackageData(carrier)} rowKey={rm => `${rm.id}`} pagination={false}
                                columns={[
                                    {
                                        key: 'type',
                                        title: 'Type',
                                        render: (_, record) => <>{record.type}</>,
                                    },
                                    {
                                        key: 'variant',
                                        title: 'Variant',
                                        render: (_, record) => <>{record.variant}</>,
                                    },
                                    {
                                        key: 'name',
                                        title: 'Name',
                                        fixed: 'left',
                                        render: (_, record) => <>{record.name}</>,
                                    },
                                    {
                                        key: 'city',
                                        title: 'City',
                                        render: (_, record) => <>{record.city}</>,
                                    },
                                    {
                                        key: 'street',
                                        title: 'Street',
                                        render: (_, record) => <>{record.street}</>,
                                    },
                                    {
                                        key: 'interval',
                                        title: 'Interval',
                                        render: (_, record) =>
                                            <span>
                                                {record.dateFrom?.format("DD.MM.YYYY")}
                                                <Progress className={`timeline-progress`}
                                                    data-progress={getProgressStatus(record.dateFrom, record.dateTo)}
                                                    percent={calcPercentageDays(record.dateFrom, record.dateTo)} showInfo={false} />
                                                {record.dateTo?.format("DD.MM.YYYY")}
                                            </span>,
                                    },
                                    {
                                        key: 'size',
                                        title: 'Size',
                                        render: (_, record) => <>{record.size}</>,
                                    },
                                    {
                                        key: 'closedFrom',
                                        title: 'Closed From',
                                        render: (_, record) => <>{record.closedFrom}</>,
                                    },
                                    {
                                        key: 'closedTo',
                                        title: 'Closed To',
                                        render: (_, record) => <>{record.closedTo}</>,
                                    },
                                    {
                                        key: 'quantity',
                                        title: 'Quantity',
                                        align: 'right',
                                        render: (_, record) => <>{record.quantity}</>,
                                    },
                                ]}
                            />
                        }
                        else {
                            return <>No quotation</>;
                        }
                    }}
                />

            </div>
            
            <CarrierEditModal data={carriersDrawerData.data} visibility={carriersDrawerData.visibility} onClose={handleCloseSubcarierModal}/>

            <Modal title="Edit campaign"
                visible={editCampaignModal.visibility}
                onOk={() => handleShowEditCampaignModal(false)}
                okButtonProps={{ loading: editCampaignModal.isLoading }}
                onCancel={() => handleShowEditCampaignModal(false)}
                cancelButtonProps={{ loading: editCampaignModal.isLoading }}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Campaign name</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Company</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <div className="card-item">
                            <label className="gray-small-label">Campaign from</label>
                            <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                                filterOption={(input, option) =>
                                    option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }
                            >
                                <Option value="1">Kontakt 1</Option>
                                <Option value="2">Kontakt 2</Option>
                            </Select>
                        </div>
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Campaign from contact</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={() => handleShowNewClientModal(true)} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined /> Create new contact
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="1">Kontakt 1</Option>
                            <Option value="2">Kontakt 2</Option>
                        </Select>
                    </div>

                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">AMS</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Date from</label>
                        <DatePicker />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Date to</label>
                        <DatePicker />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Invoice to</label>
                        <Select dropdownClassName="dropdown-new-item" allowClear showSearch
                            filterOption={(input, option) =>
                                option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={() => handleShowNewClientModal(true)} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined /> Create new contact
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="1">Kontakt 1</Option>
                            <Option value="2">Kontakt 2</Option>
                        </Select>
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Currency</label>
                        <Input />
                    </div>
                </div>

            </Modal>

            <Modal title="New client"
                wrapClassName="new-client-modal"
                visible={newClientModal.visibility}
                onOk={() => handleShowNewClientModal(false)}
                okButtonProps={{ loading: newClientModal.isLoading }}
                onCancel={() => handleShowNewClientModal(false)}
                cancelButtonProps={{ loading: newClientModal.isLoading }}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Name</label>
                        <Input />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Phone</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">E-mail</label>
                        <Input />
                    </div>
                </div>

            </Modal>

            <Modal title="Edit quotation"
                visible={editQuotationModal.visibility}
                onOk={() => handleShowEditQuotationModal(false)}
                okButtonProps={{ loading: editQuotationModal.isLoading }}
                onCancel={() => handleShowEditQuotationModal(false)}
                cancelButtonProps={{ loading: editQuotationModal.isLoading }}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Offer name</label>
                        <Input />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Offer validity from</label>
                        <DatePicker />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Offer validity to</label>
                        <DatePicker />
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Description</label>
                        <Input />
                    </div>
                </div>

            </Modal>

            <Modal className="subcarrier-modal"
                title={carriersDrawerData.data.id ? "AC Marca - Sanytol fitness: " + carriersDrawerData.data.name : "AC Marca - Sanytol fitness: New Carrier"}
                visible={false}
                onOk={() => handleCloseSubcarierModal()}
                onCancel={() => handleCloseSubcarierModal()}
                centered
                width={1600}
                footer={[
                    <div className="subcarrier-footer-buttons-container">
                        <div>
                            {selectedTabKey == "1" && <Button key="addad" type="primary" disabled={!carriersDrawerData.data.id}>Add item</Button>}
                            {subcarrierSelectedRowKeys.length > 0 &&
                                <>
                                    <Dropdown.Button className="subcarrier-dropdown-button" placement="topRight" onClick={() => handleShowMultiEditSubcarrierModal(true)}
                                        overlay={() =>
                                            <Menu className="task-detail-dropdown-menu-container">
                                                <Menu.Item key="6" onClick={() => handleDeleteSubcarrierModal()}>
                                                    <DeleteOutlined className="dropdown-custom-button-icon" />Delete
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        Edit
                                    </Dropdown.Button>
                                </>
                            }
                        </div>
                        <div>
                            <Button key="cancel" onClick={() => handleCloseSubcarierModal()}>
                                Cancel
                            </Button>
                            <Button key="submit" type="primary" onClick={() => handleCloseSubcarierModal()}>
                                Save
                            </Button>
                        </div>

                    </div>
                ]}
            >
                <div className="carrier-detail-summary-container">
                    <div className={`carrier-detail-summary-item ${selectedTabKey == "1" && "active"}`}>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price-title">Price</label>
                            <label className="carrier-detail-summary-item-price-title">Discount</label>
                            <label className="carrier-detail-summary-item-price-title">Sum</label>
                        </div>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                            <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                        </div>
                    </div>

                    <div className={`carrier-detail-summary-item ${selectedTabKey == "2" && "active"}`}>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price-title">Price</label>
                            <label className="carrier-detail-summary-item-price-title">Discount</label>
                            <label className="carrier-detail-summary-item-price-title">Sum</label>
                        </div>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                            <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                        </div>
                    </div>

                    <div className={`carrier-detail-summary-item ${selectedTabKey == "3" && "active"}`}>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price-title">Price</label>
                            <label className="carrier-detail-summary-item-price-title">Discount</label>
                            <label className="carrier-detail-summary-item-price-title">Sum</label>
                        </div>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                            <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                        </div>
                    </div>

                    <div className={`carrier-detail-summary-item ${selectedTabKey == "4" && "active"}`}>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price-title">Price</label>
                            <label className="carrier-detail-summary-item-price-title">Discount</label>
                            <label className="carrier-detail-summary-item-price-title">Sum</label>
                        </div>
                        <div className="carrier-detail-summary-item-price-container">
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                            <label className="carrier-detail-summary-item-price">50 <label className="carrier-detail-summary-currency">%</label></label>
                            <label className="carrier-detail-summary-item-price">10 000 <label className="carrier-detail-summary-currency">€</label></label>
                        </div>
                    </div>

                </div>

                <Tabs className="carrier-detail-tabs" type="card" onChange={(key) => handleChangeTab(key)}>
                    <TabPane tab="Summary" key="1">
                        <div className="subcarrier-detail-tab-container">

                            <Collapse activeKey={subcarrierCollapse} onChange={(key) => handleSubcarrierCollapse(key)}>
                                <Panel className="subcarrier-collapse-carrier-detail" header="Carriers detail" key="1">
                                    <div className="card-container">
                                        <div className="card-item one-quarter">
                                            <label className="gray-small-label">Partner</label>
                                            <Select allowClear showSearch value={carriersDrawerData.data.partner?.toLowerCase()}>
                                                <Option value="albert">Albert</Option>
                                                <Option value="billa">Billa</Option>
                                            </Select>
                                        </div>

                                        <div className="card-item one-quarter">
                                            <label className="gray-small-label">Format</label>
                                            <Select allowClear showSearch value={carriersDrawerData.data.format?.toLowerCase()} disabled={carriersDrawerData.data.partner != ""}>
                                                <Option value="hm">HM</Option>
                                                <Option value="sm">SM</Option>
                                            </Select>
                                        </div>

                                        <div className="card-item one-quarter"></div>

                                        <div className="card-item one-quarter"></div>

                                    </div>

                                    <div className="card-container">

                                        <div className="card-item one-quarter">
                                            <label className="gray-small-label">Package</label>
                                            <Select allowClear showSearch value={carriersDrawerData.data.preset?.toLowerCase().replace(/\s/g, "")} disabled={carriersDrawerData.data.type != ""}>
                                                <Option value="package1">Package 1 (BL)</Option>
                                                <Option value="package2">Package 2</Option>
                                                <Option value="package3">Package 3</Option>
                                            </Select>
                                        </div>

                                        <div className="card-item one-quarter">
                                            <label className="gray-small-label">Carrier</label>
                                            <Select allowClear showSearch value={carriersDrawerData.data.type?.toLowerCase().replace(/\s/g, "")} disabled={carriersDrawerData.data.preset != ""}>
                                                <Option value="ss">Shelf Stopper [SS]</Option>
                                                <Option value="pss">Shelf Stopper LED - Premiový [PSS]</Option>
                                                <Option value="fg">Podlahová grafika [FG]</Option>
                                                <Option value="lama">Lama</Option>
                                                <Option value="energy">Energy</Option>
                                            </Select>
                                        </div>

                                        <div className="card-item one-quarter">
                                            <label className="gray-small-label">Variant</label>
                                            <Select allowClear showSearch disabled={carriersDrawerData.data.type != "" || carriersDrawerData.data.preset != ""}>
                                                <Option value="1">A0 - EM4TD</Option>
                                            </Select>
                                        </div>

                                        <div className="card-item one-quarter"></div>

                                    </div>

                                    <div className="card-container">
                                        <div className="card-item half">
                                            <label className="gray-small-label">Individual name</label>
                                            <Input />
                                        </div>

                                        <div className="card-item half">
                                            <label className="gray-small-label">Note</label>
                                            <Input />
                                        </div>

                                    </div>
                                </Panel>
                            </Collapse>



                            <div className="card-container">
                                <div className="subcarrier-tag-container">
                                    <Tag className="subcarrier-tag" color="geekblue">Current pieces: <b>199</b></Tag>
                                    <Tag className="subcarrier-tag" color="purple">Current shops: <b>199</b></Tag>
                                    <Tag className="subcarrier-tag" color="blue">Quantity ammount: <b>199</b></Tag>
                                </div>
                            </div>

                            <div>
                                <div className="quotation-detail-table-title-container">
                                    <label className="quotation-detail-table-title">Locations</label>
                                    <div className="subcarrier-table-header">
                                        <label className="gray-small-label subcarrier-table-input-button-label">Fix counts:</label>
                                        <span className="subcarrier-table-input-button">
                                            <InputNumber size="small" />
                                            <Button className="custom-button" type="primary" size="middle">Apply</Button>
                                        </span>

                                        <div className="carrier-status-container subcarrier-table-header-dropdown-button">
                                            <label className={`quotation-status draft`} >Draft</label>
                                            <Dropdown.Button className="dropdown-custom-button dropdown-custom-button-carrier-status" data-button={'draft'} placement="topRight"
                                                overlay={() =>
                                                    <Menu className="dropdown-menu-container">
                                                        <Menu.Item key="1">
                                                            <EditOutlined className="dropdown-custom-button-icon" />Draft
                                                        </Menu.Item>
                                                        <Menu.Item key="2">
                                                            <CalendarOutlined className="dropdown-custom-button-icon" />Reserved
                                                        </Menu.Item>
                                                        <Menu.Item key="3">
                                                            <CheckCircleOutlined className="dropdown-custom-button-icon" />Ordered
                                                        </Menu.Item>
                                                        <Menu.Item key="4">
                                                            <StopOutlined className="dropdown-custom-button-icon" />Cancel
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                            >
                                                Actions
                                            </Dropdown.Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Table className="scrollable-table table-nowrap custom-pagination" dataSource={carriersDrawerData.data.id ? subCarriers : []} rowKey={subCarriers => `${subCarriers.storeId}`} scroll={{ x: true, y: '100%' }}
                                pagination={false} rowSelection={{ selectedRowKeys: subcarrierSelectedRowKeys, onChange: onSubcarrierTableSelectChange }}
                                columns={[
                                    {
                                        key: 'format',
                                        title: 'Format',
                                        render: (_, record) => <>{record.format}</>,
                                    },
                                    {
                                        key: 'type',
                                        title: 'Type',
                                        render: (_, record) =>
                                            <div className="carrier-type-container">
                                                <label className="carrier-type" style={{ background: getCarrierTypeColor(record.type) }} >{record.type}</label>
                                            </div>,
                                    },
                                    {
                                        key: 'variant',
                                        title: 'Variant',
                                        render: (_, record) => <>JHXCFDV</>
                                    },
                                    {
                                        key: 'name',
                                        title: 'Name',
                                        width: 450,
                                        render: (_, record) =>
                                            <div className="card-item">
                                                <Select allowClear showSearch optionLabelProp="label">
                                                    <Option value="1" label="Plzeň">Plzeň, Písecká 972/1, 326 00, Plzeň</Option>
                                                    <Option value="2" label="Brno">Brno, U dálnice 744, 664 42, Brno</Option>
                                                    <Option value="3" label="Praha - Nové Butovice">Praha - Nové Butovice, Radlická 117, 153 00, Praha 5</Option>
                                                </Select>
                                            </div>,
                                    },
                                    {
                                        key: 'from',
                                        title: 'From',
                                        render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                                    },
                                    {
                                        key: 'to',
                                        title: 'To',
                                        render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                                    },
                                    {
                                        key: 'size',
                                        title: 'Size',
                                        width: 90,
                                        render: (_, record) => <><InputNumber /></>,
                                    },
                                    {
                                        key: 'closedFrom',
                                        title: 'Closed From',
                                        render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                                    },
                                    {
                                        key: 'closedTo',
                                        title: 'Closed To',
                                        render: (_, record) => <><DatePicker value={moment('2022/01/01', "YYYY.MM.DD")} format="DD.MM.YYYY" /></>,
                                    },
                                    {
                                        key: 'usableAmount',
                                        title: 'Usable Amount',
                                        align: 'right',
                                        render: (_, record) => <>unlimited</>,
                                    },
                                    {
                                        key: 'disposableAmount',
                                        title: 'Disposable Amount',
                                        align: 'right',
                                        render: (_, record) => <>unlimited</>,
                                    },
                                    {
                                        key: 'quantity',
                                        title: 'Quantity',
                                        render: (_, record) => <><InputNumber className="subcarry-quantity-input" size="small" value={record.quantity} /></>,
                                    },
                                    {
                                        key: 'actions',
                                        title: 'Actions',
                                        align: 'right',
                                        fixed: 'right',
                                        width: 80,
                                        render: (_, record) =>
                                            <div className="buttons-container table-buttons-right-align">
                                                <DeleteOutlined className="icon red-hover" onClick={() => handleDeleteSubcarrierModal()} />
                                            </div>,
                                    },
                                ]}
                            />

                        </div>
                    </TabPane>
                    <TabPane tab="Media price" key="2">
                        <div className="subcarrier-detail-tab-container">

                            <div className="card-container">
                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Type</label>
                                    <Select allowClear showSearch>
                                        <Option value="1">FG (floor graphics)</Option>
                                        <Option value="2">SS (shelf stopper</Option>
                                        <Option value="3">PSS (shelf stopper led)</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Partner</label>
                                    <Select allowClear showSearch>
                                        <Option value="1">Albert</Option>
                                        <Option value="2">Billa</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Format</label>
                                    <Select allowClear showSearch>
                                        <Option value="1">HM</Option>
                                        <Option value="2">HM</Option>
                                    </Select>
                                </div>

                                <div className="card-item one-quarter">
                                    <label className="gray-small-label">Fix counts</label>
                                    <Input.Group className="subcarrier-input-group-container" compact>
                                        <InputNumber />
                                        <Button type="primary">Change</Button>
                                    </Input.Group>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="Production prices" key="3">
                        <div className="carrier-detail-tab-container">
                            <div className="input-row-container">

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item">
                                        <label className="gray-small-label">Media netnet sum</label>
                                        <Input addonAfter={"€"} />
                                    </div>
                                </div>

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item spacing">
                                        <label className="gray-small-label">Prod. sales list price</label>
                                        <Input addonAfter={"%"} />
                                    </div>
                                    <div className="input-row-item">
                                        <label className="gray-small-label"></label>
                                        <Input addonAfter={"€"} />
                                    </div>
                                </div>

                            </div>

                            <div className="input-row-container">

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item spacing">
                                        <label className="gray-small-label">Prod Buffer</label>
                                        <Input addonAfter={"%"} />
                                    </div>
                                    <div className="input-row-item">
                                        <label className="gray-small-label"></label>
                                        <Input addonAfter={"PCS"} />
                                    </div>
                                </div>


                                <div className="input-multiple-items-container">
                                    <div className="input-row-item spacing">
                                        <label className="gray-small-label">Total nr</label>
                                        <Input addonAfter={"%"} />
                                    </div>
                                    <div className="input-row-item">
                                        <label className="gray-small-label"></label>
                                        <Input addonAfter={"PCS"} />
                                    </div>
                                </div>

                            </div>

                            <div className="input-row-container">

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item">
                                        <label className="gray-small-label">Sum prod price</label>
                                        <Input addonAfter={"€"} disabled />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="Logistic prices" key="4">
                        <div className="carrier-detail-tab-container">
                            <div className="input-row-container">

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item spacing">
                                        <label className="gray-small-label">Log sales list price</label>
                                        <Input addonAfter={"%"} />
                                    </div>
                                    <div className="input-row-item">
                                        <label className="gray-small-label"></label>
                                        <Input addonAfter={"€"} />
                                    </div>
                                </div>

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item spacing">
                                        <label className="gray-small-label">Log Buffer</label>
                                        <Input addonAfter={"%"} />
                                    </div>
                                    <div className="input-row-item">
                                        <label className="gray-small-label"></label>
                                        <Input addonAfter={"PCS"} />
                                    </div>
                                </div>

                            </div>

                            <div className="input-row-container">

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item spacing">
                                        <label className="gray-small-label">Total nr</label>
                                        <Input addonAfter={"%"} />
                                    </div>
                                    <div className="input-row-item">
                                        <label className="gray-small-label"></label>
                                        <Input addonAfter={"PCS"} />
                                    </div>
                                </div>

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item">
                                        <label className="gray-small-label">Sum log price</label>
                                        <Input addonAfter={"€"} />
                                    </div>
                                </div>

                            </div>

                            <div className="input-row-container">

                                <div className="input-multiple-items-container">
                                    <div className="input-row-item">
                                        <label className="gray-small-label">Sum log price/store</label>
                                        <Input addonAfter={"€"} disabled />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </TabPane>
                </Tabs>

            </Modal>

            <Modal title="Multiple edit"
                visible={multiEditSubcarrierModal.visibility}
                onOk={() => handleShowMultiEditSubcarrierModal(false)}
                okButtonProps={{ loading: multiEditSubcarrierModal.isLoading }}
                onCancel={() => handleShowMultiEditSubcarrierModal(false)}
                cancelButtonProps={{ loading: multiEditSubcarrierModal.isLoading }}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Status</label>
                        <Select allowClear showSearch>
                            <Option value="1">Reserved</Option>
                            <Option value="2">Draft</Option>
                        </Select>
                    </div>
                </div>

                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Closed from</label>
                        <DatePicker />
                    </div>

                    <div className="card-item">
                        <label className="gray-small-label">Closed to</label>
                        <DatePicker />
                    </div>
                </div>

            </Modal>

            <Modal title="Summary preview"
                visible={summaryPreviewModal}
                onOk={() => handleShowSummaryPreviewModal(false)}
                onCancel={() => handleShowSummaryPreviewModal(false)}
                centered
                width={1100}
            >
                <img className="screenshot-image" src={summaryPreview} />
            </Modal>

            <Modal title="Change status"
                visible={multipleChangeStatusModal}
                onOk={() => handleShowMultipleChangeStatusModal(false)}
                onCancel={() => handleShowMultipleChangeStatusModal(false)}
                centered
            >
                <div className="card-container">
                    <div className="card-item">
                        <label className="gray-small-label">Status</label>
                        <Select allowClear showSearch>
                            <Option value="1">Reserved</Option>
                            <Option value="2">Draft</Option>
                            <Option value="3">Ordered</Option>
                        </Select>
                    </div>
                </div>
            </Modal>

            <FooterComponent />
        </div>
    );
}