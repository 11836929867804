import { FooterComponent } from '../components/footer-component';
import HeaderMenu from '../components/header-component';
import { reservationListBreadCrumb } from '../data/breadcrumb-data';
import tourplan from '../resources/screenshots/tourplan.png';

export function ReservationManager() {

    return (
        <div className="page-content">
            <HeaderMenu title="Reservations" breadCrumbData={reservationListBreadCrumb} />

            <div className="page-body">
            <div className="reservation-container">
                    <img className="screenshot-image" src={tourplan} />
                </div>
            </div>

            <FooterComponent />
        </div>
    );
}