import { FooterComponent } from '../components/footer-component';
import HeaderMenu from '../components/header-component';
import { reservationListBreadCrumb } from '../data/breadcrumb-data';
import reservationList from '../resources/screenshots/reservation_list.png';
import reservation from '../resources/screenshots/reservation.png';
import tourplan from '../resources/screenshots/tourplan.png';
import moment from 'moment';
import { Button, Dropdown, Menu, Modal, Progress, Select, Table } from 'antd';
import { Key, useEffect, useState } from 'react';
import { EditOutlined, CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, StopOutlined, CheckCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { calcPercentageDays, getOfferExpiration, getProgressStatus } from '../tools/progressbar-duration-helper';
import { PartnerTag } from '../components/carrier-edit-modal';

const { Option } = Select;
const { confirm } = Modal;

const carriers = [
    {
        id: 1,
        preset: "",
        campaignName: "AC Marca - Sanytol fitness",
        campaignId: 1,
        name: "3D Stojna - Lama",
        individualName: "Individual Name",
        type: "LAMA",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 4,
        length: 2,
        partner: "Albert",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 10,
        productionPrice: 21475,
        productionDiscount: 11,
        logisticPrice: 12347,
        logisticDiscount: 58,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 3, 10)),
    },
    {
        id: 2,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Shelf Stopper",
        individualName: "",
        type: "SS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Albert",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 15,
        productionPrice: 21475,
        productionDiscount: 47,
        logisticPrice: 12347,
        logisticDiscount: 36,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 3,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Shelf Stopper LED - Premiový",
        individualName: "",
        type: "PSS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 10,
        length: 2,
        partner: "Globus",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 3,
        productionPrice: 21475,
        productionDiscount: 27,
        logisticPrice: 12347,
        logisticDiscount: 45,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 4,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Podlahová grafika",
        individualName: "",
        type: "FG",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 2,
        length: 2,
        partner: "Tesco",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 25,
        productionPrice: 21475,
        productionDiscount: 44,
        logisticPrice: 12347,
        logisticDiscount: 15,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 6, 10)),
        dateTo: moment(new Date(2022, 8, 10)),
    },
    {
        id: 5,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Energy",
        individualName: "",
        type: "Energy",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Electro World",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 17,
        productionPrice: 21475,
        productionDiscount: 67,
        logisticPrice: 12347,
        logisticDiscount: 68,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 6,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "3D Stojna - Lama",
        individualName: "",
        type: "LAMA",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 6,
        length: 2,
        partner: "Penny",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 6,
        productionPrice: 21475,
        productionDiscount: 52,
        logisticPrice: 12347,
        logisticDiscount: 25,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 7,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Shelf Stopper",
        individualName: "",
        type: "SS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Kaufland",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 0,
        productionPrice: 21475,
        productionDiscount: 4,
        logisticPrice: 12347,
        logisticDiscount: 65,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 8,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Shelf Stopper LED - Premiový",
        individualName: "",
        type: "PSS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 0,
        length: 2,
        partner: "Makro",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 29,
        productionPrice: 21475,
        productionDiscount: 3,
        logisticPrice: 12347,
        logisticDiscount: 78,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 9,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Podlahová grafika",
        individualName: "",
        type: "FG",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 0,
        length: 2,
        partner: "Rossmann",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 35,
        productionPrice: 21475,
        productionDiscount: 99,
        logisticPrice: 12347,
        logisticDiscount: 37,
        status: "Ordered",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 10,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Energy",
        individualName: "",
        type: "Energy",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 10,
        length: 2,
        partner: "Albert",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 11,
        productionPrice: 21475,
        productionDiscount: 42,
        logisticPrice: 12347,
        logisticDiscount: 78,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 11,
        preset: "Package 1",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Promo Package",
        individualName: "",
        type: "",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 7,
        length: 2,
        partner: "Makro",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 40,
        productionPrice: 21475,
        productionDiscount: 36,
        logisticPrice: 12347,
        logisticDiscount: 46,
        status: "Ordered",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 12,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Shelf Stopper",
        individualName: "",
        type: "SS",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Albert",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 15,
        productionPrice: 21475,
        productionDiscount: 47,
        logisticPrice: 12347,
        logisticDiscount: 36,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 13,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Shelf Stopper LED - Premiový",
        individualName: "",
        type: "PSS LED",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 10,
        length: 2,
        partner: "Globus",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 3,
        productionPrice: 21475,
        productionDiscount: 27,
        logisticPrice: 12347,
        logisticDiscount: 45,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 14,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Podlahová grafika",
        individualName: "",
        type: "FG",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 2,
        length: 2,
        partner: "Tesco",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 25,
        productionPrice: 21475,
        productionDiscount: 44,
        logisticPrice: 12347,
        logisticDiscount: 15,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 15,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "Energy",
        individualName: "",
        type: "Energy",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Electro World",
        format: "SM",
        mediaPrice: 15748,
        mediaDiscount: 17,
        productionPrice: 21475,
        productionDiscount: 67,
        logisticPrice: 12347,
        logisticDiscount: 68,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 16,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 3,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 19,
        productionPrice: 21475,
        productionDiscount: 82,
        logisticPrice: 12347,
        logisticDiscount: 2,
        status: "Cancelled",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 17,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 8,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 8,
        productionPrice: 21475,
        productionDiscount: 25,
        logisticPrice: 12347,
        logisticDiscount: 32,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 18,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 4,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 1,
        productionPrice: 21475,
        productionDiscount: 36,
        logisticPrice: 12347,
        logisticDiscount: 68,
        status: "Cancelled",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 19,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 1,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 37,
        productionPrice: 21475,
        productionDiscount: 64,
        logisticPrice: 12347,
        logisticDiscount: 45,
        status: "Cancelled",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 20,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 24,
        productionPrice: 21475,
        productionDiscount: 7,
        logisticPrice: 12347,
        logisticDiscount: 94,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 21,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 5,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 14,
        productionPrice: 21475,
        productionDiscount: 55,
        logisticPrice: 12347,
        logisticDiscount: 82,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 22,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 3,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 34,
        productionPrice: 21475,
        productionDiscount: 69,
        logisticPrice: 12347,
        logisticDiscount: 64,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 23,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 0,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 2,
        productionPrice: 21475,
        productionDiscount: 20,
        logisticPrice: 12347,
        logisticDiscount: 43,
        status: "Draft",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 24,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 18,
        productionPrice: 21475,
        productionDiscount: 93,
        logisticPrice: 12347,
        logisticDiscount: 20,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 25,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 9,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 10,
        productionPrice: 21475,
        productionDiscount: 9,
        logisticPrice: 12347,
        logisticDiscount: 65,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 26,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 7,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 28,
        productionPrice: 21475,
        productionDiscount: 10,
        logisticPrice: 12347,
        logisticDiscount: 78,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 27,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 1,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 37,
        productionPrice: 21475,
        productionDiscount: 35,
        logisticPrice: 12347,
        logisticDiscount: 17,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 28,
        preset: "",
        campaignName: "Albert opening Moskevská",
        campaignId: 2,
        name: "FG",
        individualName: "",
        type: "FG_A0",
        variant: "A0 - EM4TD",
        quantity: 10,
        reserved: 1,
        length: 2,
        partner: "Spar Group",
        format: "HM",
        mediaPrice: 15748,
        mediaDiscount: 37,
        productionPrice: 21475,
        productionDiscount: 35,
        logisticPrice: 12347,
        logisticDiscount: 17,
        status: "Reserved",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
]

const quotations = [
    {
        id: 1,
        campaignId: 1,
        offerName: "Merchendising CZ",
        offerValidityFrom: moment(new Date(2022, 2, 10)),
        offerValidityTo: moment(new Date(2022, 5, 10)),
        approveDate: moment(new Date(2022, 2, 10)),
        reserved: "12",
        description: "Description here",
        total: "50 000",
        currency: "CZK",
    },
]

const carriersPackage = [
    {
        id: 1,
        packageId: 1,
        type: "LAMA",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 3, 10)),
        dateTo: moment(new Date(2022, 4, 9)),
    },
    {
        id: 2,
        packageId: 1,
        type: "LAMA",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 4, 10)),
        dateTo: moment(new Date(2022, 5, 10)),
    },
    {
        id: 3,
        packageId: 1,
        type: "SS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 4,
        packageId: 1,
        type: "SS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 5,
        packageId: 1,
        type: "PSS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 6,
        packageId: 1,
        type: "FG",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 7,
        packageId: 1,
        type: "PSS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 8,
        packageId: 1,
        type: "PSS",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
    {
        id: 9,
        packageId: 1,
        type: "Energy",
        variant: "JHXCFDV",
        name: "Name 123",
        city: "Plzeň",
        street: "Písecká 972/1, 326 00, Plzeň",
        size: "12K",
        closedFrom: "21. 04. 2022",
        closedTo: "-",
        usableAmount: "10",
        dispodableAmount: "10",
        quantity: "1",
        dateFrom: moment(new Date(2022, 2, 10)),
        dateTo: moment(new Date(2022, 6, 10)),
    },
]

interface TypeColor {
    campaignId: number;
    color: string;
}

enum colors {
    purple = "#531dab",
    geekblue = "#1d39c4",
    blue = "#096dd9",
    cyan = "#08979c",
}

export function ReservationList() {
    const [tableOptions, setTableOptions] = useState({ pageSize: 50, showSizeChanger: false, total: 1, current: 1, loading: false });
    const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);
    const [quotationRowCollor, setQuotationRowCollor] = useState([] as TypeColor[]);

    useEffect(() => {
        if (quotationRowCollor.length < 1) {
            setupQuotationRowColors();            
        }        
    });
    
    const onTableSelectChange = (selectedRowKeys: Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const handleChangePaginationSize = (pageSize: number) => {
        setTableOptions({ pageSize: pageSize, showSizeChanger: false, total: 1, current: 1, loading: false });
    };

    const setupQuotationRowColors = () => {
        const campaigns = Array.from(new Set(carriers.map(x => x.campaignId)));
        let colorOrder = 0;

        for (const campaign of campaigns) {

            let typeColorItem = {} as TypeColor;
            typeColorItem.campaignId = campaign;
            typeColorItem.color = Object.entries(colors)[colorOrder][1];
            setQuotationRowCollor(currentArray => [...currentArray, typeColorItem]);

            if (colorOrder == 3) {
                colorOrder = 0;
            } else {
                colorOrder++;
            }
        }
    };

    const getCarriersPackageData = (carrier: any) => {
        const filterData = carriersPackage.filter(x => x.type == carrier.type)
        if(filterData.length > 0) {
            return filterData;
        } else {
            return carriersPackage;
        }
    }

    const getQuotationListColoRow = (campaignId: number) => {
        const color = quotationRowCollor.find(x => x.campaignId == campaignId);
        if (color) {
            return color.color;
        } else {
            return "#aaa";
        }
    }

    return (
        <div className="page-content">
            <HeaderMenu title="Reservations" breadCrumbData={reservationListBreadCrumb} />

            <div className="page-body">
                <Table className="scrollable-table table-nowrap custom-pagination" data-table="reservation-table" dataSource={carriers} rowKey={carriers => `${carriers.id}`} scroll={{ x: true, y: '100%' }}
                    pagination={tableOptions} loading={tableOptions.loading} rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onTableSelectChange }}
                    rowClassName={(x) => `${x.preset && 'carrier-package-row'} ${x.status?.toLowerCase() == "cancelled" && 'carrier-cancelled-row'}`}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="quotation-detail-table-summary-ammount-row">
                                <Table.Summary.Cell index={0} colSpan={9}></Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={1} >280</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={2} >425 196 CZK</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={3} ></Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={4} >425 196 CZK</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={5} ></Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={6} >425 196 CZK</Table.Summary.Cell>
                                <Table.Summary.Cell align='right' index={7} ></Table.Summary.Cell>
                                <Table.Summary.Cell index={8} colSpan={2} ></Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row className="pagination-row">
                                <Table.Summary.Cell className="summary-cell" index={0} colSpan={17}>
                                    <div className="campaign-legend-container">
                                        <Button className="custom-button" type="primary" onClick={() => /*handleShowSubcarierModal()*/console.log("TODO")}>New Carrier</Button>
                                        {selectedRowKeys.length > 0 &&
                                            <Dropdown.Button className="dropdown-custom-button dropdown-primary-button" placement="topRight" onClick={() => /*handleShowMultipleChangeStatusModal(true)*/console.log("TODO")}
                                                overlay={() =>
                                                    <Menu className="dropdown-menu-container">
                                                        <Menu.Item key="1">
                                                            <CopyOutlined className="dropdown-custom-button-icon" />Duplicate
                                                        </Menu.Item>
                                                        <Menu.Item key="2">
                                                            <DeleteOutlined className="dropdown-custom-button-icon" />Delete
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                            >
                                                Change status
                                            </Dropdown.Button>
                                        }
                                    </div>
                                    <Select className="pagination-changer" value={tableOptions.pageSize} onChange={e => handleChangePaginationSize(e)} >
                                        <Option value={20}>20</Option>
                                        <Option value={50}>50</Option>
                                        <Option value={100}>100</Option>
                                    </Select>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                        </Table.Summary>
                    )}
                    columns={[
                        {
                            key: 'campaignName',
                            title: () =>
                                <div className="name-error">
                                    <ExclamationCircleOutlined className="error-cell" />
                                    <label>Campaign name</label>
                                </div>,
                            render: (_, record) =>
                                <div className="name-error">
                                    {getOfferExpiration(record.dateFrom, record.dateTo) == "expired" ? <ExclamationCircleOutlined className="error-cell red" /> : <ExclamationCircleOutlined className="error-cell hidden" />}
                                    <div className="quotation-list-color-row-container">
                                        <label className="quotation-list-color-row" style={{ background: getQuotationListColoRow(record.campaignId) }} >{record.campaignName}</label>
                                    </div>
                                </div>,
                        },
                        {
                            key: 'name',
                            title: 'Offer name',
                            fixed: 'left',                            
                            render: (_, record) => <label className={`pno-cell ${record.individualName && 'carriers-individual-name'}`}>{record.individualName ? record.individualName : record.name}</label>,
                        },
                        {
                            key: 'interval',
                            title: 'Interval',
                            render: (_, record) =>
                                <span>
                                    {record.dateFrom?.format("DD.MM.YYYY")}
                                    <Progress className={`timeline-progress`}
                                        data-progress={getProgressStatus(record.dateFrom, record.dateTo, record.status?.toLowerCase() == "cancelled" && true)}
                                        percent={record.status?.toLowerCase() == "cancelled" ? 0 : calcPercentageDays(record.dateFrom, record.dateTo)} showInfo={false} />
                                    {record.dateTo?.format("DD.MM.YYYY")}
                                </span>,
                        },
                        {
                            key: 'partner',
                            title: 'Partner',
                            render: (_, record) =>
                                <><PartnerTag item={record} /></>,
                        },
                        {
                            key: 'format',
                            title: 'Format',
                            render: (_, record) => <>{record.format}</>,
                        },
                        {
                            key: 'type',
                            title: 'Type',
                            render: (_, record) => <>{record.preset ? record.preset : record.type}</>,
                        },
                        {
                            key: 'variant',
                            title: 'Variant',
                            render: (_, record) => <>{record.variant}</>,
                        },
                        {
                            key: 'quantity',
                            title: 'Quantity',
                            align: 'right',
                            width: 60,
                            render: (_, record) => <>{record.quantity}</>,
                        },
                        {
                            key: 'mediaPrice',
                            title: 'Media Price',
                            align: 'right',
                            render: (_, record) => <label className="bold-text">{record.mediaPrice?.toLocaleString()} CZK</label>,
                        },
                        {
                            key: 'mediaDiscount',
                            title: 'M Dis',
                            align: 'center',
                            className: 'table-no-padding',
                            width: 50,
                            render: (_, record) => <div className="carrier-discount-cell" style={{ background: `rgba(177, 177, 177,${record.mediaDiscount ? record.mediaDiscount / 100 * 1.5 : 0})`, }}>{record.mediaDiscount} %</div>,
                        },
                        {
                            key: 'productionPrice',
                            title: 'Production Price',
                            align: 'right',
                            render: (_, record) => <label className="bold-text">{record.productionPrice?.toLocaleString()} CZK</label>,
                        },
                        {
                            key: 'productionDiscount',
                            title: 'P Dis',
                            align: 'center',
                            className: 'table-no-padding',
                            width: 50,
                            render: (_, record) => <div className="carrier-discount-cell" style={{ background: `rgba(177, 177, 177,${record.mediaDiscount ? record.mediaDiscount / 100 * 1.5 : 0})`, }}>{record.productionDiscount} %</div>,
                        },
                        {
                            key: 'logisticPrice',
                            title: 'Logistic Price',
                            align: 'right',
                            render: (_, record) => <label className="bold-text">{record.logisticPrice?.toLocaleString()} CZK</label>,
                        },
                        {
                            key: 'logisticDiscount',
                            title: 'L Dis',
                            align: 'center',
                            className: 'table-no-padding',
                            width: 50,
                            render: (_, record) => <div className="carrier-discount-cell" style={{ background: `rgba(177, 177, 177,${record.mediaDiscount ? record.mediaDiscount / 100 * 1.5 : 0})`, }}>{record.logisticDiscount} %</div>,
                        },
                        {
                            key: 'status',
                            title: 'Status',
                            align: 'right',
                            render: (_, record) =>
                                <div className="carrier-status-container">
                                    <label className={`quotation-status ${record.status?.toLowerCase()}`} >{record.status}</label>
                                    <Dropdown.Button className="dropdown-custom-button dropdown-custom-button-carrier-status" data-button={record.status?.toLowerCase()} placement="topRight"
                                        overlay={() =>
                                            <Menu className="dropdown-menu-container">
                                                <Menu.Item key="1">
                                                    <EditOutlined className="dropdown-custom-button-icon" />Draft
                                                </Menu.Item>
                                                <Menu.Item key="2">
                                                    <CalendarOutlined className="dropdown-custom-button-icon" />Reserved
                                                </Menu.Item>
                                                <Menu.Item key="3">
                                                    <CheckCircleOutlined className="dropdown-custom-button-icon" />Ordered
                                                </Menu.Item>
                                                <Menu.Item key="4">
                                                    <StopOutlined className="dropdown-custom-button-icon" />Cancel
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        Actions
                                    </Dropdown.Button>
                                </div>,
                        },
                        {
                            key: 'actions',
                            title: 'Actions',
                            align: 'right',
                            fixed: 'right',
                            render: (_, record) =>
                                <div className="buttons-container table-buttons-right-align">
                                    {/*<EditOutlined className="icon" onClick={() => handleShowSubcarierModal(record)} />
                                    <CopyOutlined className="icon" onClick={() => handleDuplicateCarrierModal()} />
                        <DeleteOutlined className="icon red-hover" onClick={() => handleDeleteCarrierModal()} />*/}
                                </div>,
                        },
                    ]}
                    expandedRowRender={(carrier) => {

                        if (quotations.length > 0) {
                            return <Table className="nested-table nested-quotation-table nested-package-carrier-table" dataSource={getCarriersPackageData(carrier)} rowKey={rm => `${rm.id}`} pagination={false}
                                columns={[
                                    {
                                        key: 'type',
                                        title: 'Type',
                                        render: (_, record) => <>{record.type}</>,
                                    },
                                    {
                                        key: 'variant',
                                        title: 'Variant',
                                        render: (_, record) => <>{record.variant}</>,
                                    },
                                    {
                                        key: 'name',
                                        title: 'Name',
                                        fixed: 'left',
                                        render: (_, record) => <>{record.name}</>,
                                    },
                                    {
                                        key: 'city',
                                        title: 'City',
                                        render: (_, record) => <>{record.city}</>,
                                    },
                                    {
                                        key: 'street',
                                        title: 'Street',
                                        render: (_, record) => <>{record.street}</>,
                                    },
                                    {
                                        key: 'interval',
                                        title: 'Interval',
                                        render: (_, record) =>
                                            <span>
                                                {record.dateFrom?.format("DD.MM.YYYY")}
                                                <Progress className={`timeline-progress`}
                                                    data-progress={getProgressStatus(record.dateFrom, record.dateTo)}
                                                    percent={calcPercentageDays(record.dateFrom, record.dateTo)} showInfo={false} />
                                                {record.dateTo?.format("DD.MM.YYYY")}
                                            </span>,
                                    },
                                    {
                                        key: 'size',
                                        title: 'Size',
                                        render: (_, record) => <>{record.size}</>,
                                    },
                                    {
                                        key: 'closedFrom',
                                        title: 'Closed From',
                                        render: (_, record) => <>{record.closedFrom}</>,
                                    },
                                    {
                                        key: 'closedTo',
                                        title: 'Closed To',
                                        render: (_, record) => <>{record.closedTo}</>,
                                    },
                                    {
                                        key: 'quantity',
                                        title: 'Quantity',
                                        align: 'right',
                                        render: (_, record) => <>{record.quantity}</>,
                                    },
                                ]}
                            />
                        }
                        else {
                            return <>No quotation</>;
                        }
                    }}
                />
                    {/*<img className="screenshot-image" src={tourplan} />*/}
            </div>

            <FooterComponent />
        </div>
    );
}