import { useState } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { HomeOutlined, FolderOpenOutlined, FileDoneOutlined, CalendarOutlined, SnippetsOutlined } from '@ant-design/icons';

const { Sider } = Layout;

export function RootLayout() {

    const [collapsed, setCollapsed] = useState(true);

    const path = useLocation().pathname.substring(1);

    return (
        <>
            <Sider collapsible collapsed={collapsed} onCollapse={state => setCollapsed(state)} >
                <div className="menu-container">
                    <div className="logo" />
                    <Menu theme="dark" mode="inline" selectedKeys={[path == '' ? 'home' : path]} >
                        <Menu.Item key="home" icon={<HomeOutlined />}>
                            <Link to="/">Home</Link>
                        </Menu.Item>
                        <Menu.Item key="campaigns" icon={<FolderOpenOutlined />}>
                            <Link to="/campaigns">Campaigns</Link>
                        </Menu.Item>
                        <Menu.Item key="quotation-list" icon={<FileDoneOutlined />}>
                            <Link to="/quotation-list">Quotations</Link>
                        </Menu.Item>
                        <Menu.Item key="reservation-list" icon={<CalendarOutlined />}>
                            <Link to="/reservation-list">Reservations</Link>
                        </Menu.Item>
                        <Menu.Item key="reservation-manager" icon={<CalendarOutlined />}>
                            <Link to="/reservation-manager">Reservation manager</Link>
                        </Menu.Item>
                        <Menu.Item key="subcarrier-list" icon={<SnippetsOutlined />}>
                            <Link to="/subcarrier-list">Subcarriers</Link>
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>

            <Layout className="site-layout">
                <Outlet />
            </Layout>

        </>
    );
}